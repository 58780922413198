@use "theme/utils/variables.module.scss" as *;

.space-configurator-steps-wizard {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 1.5rem;
  overflow: hidden;
  &.is-mobile {
    padding-right: 0;
    padding-bottom: calc($RECAP_HEADER_HEIGHT + $BUTTON_MEDIUM_HEIGHT + 1.5rem);
  }
  @media only screen and (max-width: $desktop_S) {
    flex: 1;
    padding-right: 1rem;
    margin-top: 0;
    height: 100%;
  }
  @media only screen and (max-width: $tablet) {
    padding: 0;
  }
  &-accordion {
    width: 100%;
  }
  .steps-wizard-title-desktop {
    font-family: $secondaryFont;
    font-size: $defaultFont;
    text-align: left;
    color: $accent;
    width: 100%;
    margin-top: 0;
    margin-bottom: 1.5rem;
  }
  .steps-wizard-title-mobile {
    @media only screen and (max-width: $tablet) {
      display: none;
    }
    .title {
      margin: 0;
      font-family: $primaryFontBolder;
      font-size: $largeFont;
      span {
        font-weight: 100;
        font-family: $primaryFont;
      }
      + .subtitle {
        margin-top: 0.5rem;
      }
    }
  }
  .subtitle {
    font-size: $mediumFont;
    font-family: $primaryFont;
  }
  .space-configurator-steps-wizard-accordion {
    padding-bottom: 100vh;
    overflow-y: auto;
    @media only screen and (max-width: $desktop_S) {
      height: 100%;
    }
  }
}
