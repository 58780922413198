@use "theme/utils/variables.module.scss" as *;

#complex-modal {
  .modal-container {
    height: 100%;

    .space-configurator-complexStep {
      padding: 0;
      height: 100%;

      .complex-step-container {
        margin: 0;
        height: 100%;
        display: flex;
        flex-direction: column;

        .space-configurator-zoneFilter {
          padding: 1rem 0;
          margin: 0;
          justify-content: center;
        }

        .space-configurator-card-list {
          overflow-y: auto;
          height: 100%;
          padding: 0 3.5rem;

          .card-wrapper {
            width: 100%;

            .space-configurator-card-item {
              width: 100%;

              .tecma-card {
                width: 100%;
                max-width: 312px;
                margin: auto;

                &-content {
                  margin-top: 6.625rem;
                }
              }
            }

            &:last-child {
              margin-bottom: 10rem;
            }
          }
        }
      }
    }
  }
}
