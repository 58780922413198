@use "theme/utils/variables.module.scss" as *;

.select-language-button {
  gap: 0 0.25rem;
  @media screen and (max-width: $tablet) {
    gap: 0 0.5rem;
  }
  .tecma-icon {
    transition: transform 0.5s ease;
    @media screen and (max-width: $tablet) {
      width: 1rem;
      height: 1rem;
    }
  }

  &.open {
    .tecma-icon {
      transform: rotate(180deg);
    }
  }
}

.language-selector {
  ul {
    background-color: $general;
    box-shadow: 0px 45px 41px rgba(166, 166, 166, 0.05),
      0px 29.1667px 24.0116px rgba(166, 166, 166, 0.037963),
      0px 17.3333px 13.0593px rgba(166, 166, 166, 0.0303704),
      0px 9px 6.6625px rgba(166, 166, 166, 0.025),
      0px 3.66667px 3.34074px rgba(166, 166, 166, 0.0196296),
      0px 0.833333px 1.61343px rgba(166, 166, 166, 0.012037);

    li {
      padding: 0.25rem 0.5rem;
      .tecma-button {
        color: $on-general;
        justify-content: flex-start;
        padding: 0 1rem;
        font-family: $secondaryFontBolder;
        gap: 0 0.5rem;

        &:hover {
          background-color: $general-hover;
        }

        .tecma-icon path {
          fill: $on-general;
        }
      }
    }

    .tecma-dropDown-divider {
      margin: 0 0 0 1.5rem;
      width: calc(100% - 3rem);
      background-color: $general-border;
    }
  }

  :last-child.tecma-dropDown-divider {
    display: none;
  }
}

.language-selector-mobile {
  width: 100%;

  .tecma-accordion-content {
    .tecma-accordion-content-header {
      flex-direction: row-reverse;
      justify-content: flex-end;
      padding: 0 0.5rem;
      border-radius: 0.125rem;
      &:hover {
        background-color: unset;
      }
      p {
        font-size: $mediumFont;
        font-family: $secondaryFontBolder;
        color: $on-general;
        margin: 0.5rem 0;
      }
      &.open {
        background-color: $general;
      }
      .tecma-button {
        width: 100%;
        justify-content: flex-start;

        &:hover {
          background-color: transparent;
        }

        .tecma-icon {
          width: 1rem;
          height: 1rem;
        }
      }
    }
    &-panel-wrapper {
      .tecma-accordion-content-panel {
        .tecma-button {
          width: 100%;
          justify-content: flex-start;
          border-radius: 0.125rem;
          &:hover {
            background-color: $general-hover;
          }
        }
      }
    }
  }
}
