// @import '../../../../theme/utils/variables.module.scss';
@use "theme/utils/variables.module.scss" as *;

[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  position: absolute;
  left: 0;
  opacity: 0.01;
}

[type="checkbox"]:not(:checked) + .am-custom-checkbox,
[type="checkbox"]:checked + .am-custom-checkbox {
  position: relative;
  margin-right: 2em;
  font-size: 1.05em;
  line-height: 1.7;
  cursor: pointer;
  margin-top: 3px;
}

[type="checkbox"]:not(:checked) + .am-custom-checkbox:before,
[type="checkbox"]:checked + .am-custom-checkbox:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 1em;
  height: 1em;
  border: 1px solid $accent;
  background: $general;
  border-radius: 0.2em;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.275s;
  transition: all 0.275s;
}

[type="checkbox"]:not(:checked) + .am-custom-checkbox.am-checkbox-error:before {
  border: 1px solid $danger;
}

[type="checkbox"]:not(:checked) + .am-custom-checkbox:after,
[type="checkbox"]:checked + .am-custom-checkbox:after {
  content: "✕";
  position: absolute;
  top: 9px;
  left: 2.5px;
  font-size: 1em;
  color: $accent;
  line-height: 0;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

[type="checkbox"]:not(:checked) + .am-custom-checkbox:after {
  opacity: 0;
  -webkit-transform: scale(0) rotate(45deg);
  transform: scale(0) rotate(45deg);
}

[type="checkbox"]:checked + .am-custom-checkbox:after {
  opacity: 1;
  -webkit-transform: scale(1) rotate(0);
  transform: scale(1) rotate(0);
}

[type="checkbox"]:disabled:not(:checked) + .am-custom-checkbox:before,
[type="checkbox"]:disabled:checked + .am-custom-checkbox:before {
  box-shadow: none;
  border-color: $disabled;
  background-color: $disabled;
}

[type="checkbox"]:disabled:checked + .am-custom-checkbox:after {
  color: $on-primary-disabled;
}

[type="checkbox"]:disabled + .am-custom-checkbox {
  color: $on-primary-disabled;
}

/* Accessibility */
[type="checkbox"]:checked:focus + .am-custom-checkbox:before,
[type="checkbox"]:not(:checked):focus + .am-custom-checkbox:before {
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}

.am-label {
  height: 0;
}

.am-register-required {
  font-size: 12px;
  width: 100%;
  color: $on-primary-sub;
  opacity: 65%;
  text-align: center;
  font-family: Inconsolata, monospace;
}

.am-errors-container {
  width: 100%;
  height: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.am-register-input:not(:placeholder-shown) + label,
.am-register-input:focus + label,
.am-input:not(:placeholder-shown) + label,
.am-input:focus + label,
.am-input-code:focus + label {
  display: flex;
  color: $accent;
  font-size: 10px;
  z-index: 2;
  position: relative;
  transform-origin: top left;
  transform: translate(12px, -43px) scale(1);
  transition: all 0.1s ease-in-out;
}

.am-register-input:not(:placeholder-shown),
.am-register-input:focus,
.am-input:not(:placeholder-shown),
.am-input:focus {
  outline: none;
  padding: 14px 2px 1px;
  transition: padding 0.1s ease-in-out;
}

.am-input-code:focus {
  outline: none;
  background-color: $accent-light;
}

.am-input:focus::placeholder,
.am-input-code:focus::placeholder,
.am-register-input:focus::placeholder {
  color: transparent;
}

.am-errors-label {
  color: $danger;
  font-size: 11px;
  float: left;

  &:first-child {
    margin-bottom: 0;
  }

  &:not(:first-child) {
    margin: 0;
  }
}

.am-submit-button {
  cursor: pointer;
  width: 70%;
  background-color: $accent;
  color: $on-accent;
  text-transform: uppercase;
  justify-content: center;
  height: 40px;
  border: 0;
  transition: background-color 0.1s ease-in-out;

  &-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate(0, 50%);
    right: 0;
    z-index: 2;
  }
}

.am-submit-button:disabled {
  background-color: $accent-light;
}

/* used to prevent wrong style in ios devices */
input:matches([type="button"], [type="submit"], [type="reset"]) {
  -webkit-appearance: none;
  border-radius: 0;
}

.am-input,
.am-input-code,
.am-register-input,
.am-confirm-input,
.am-right-form .react-tel-input .am-register-phoneNumber {
  height: 40px;
  width: 100%;
  box-sizing: border-box;
  outline: none;
  border-bottom: 3px solid $accent;
  color: $on-general;
  border-top: none;
  border-left: none;
  border-right: none;
  text-indent: 10px;
  border-radius: 0;
}

.am-input-code {
  border: 3px solid $accent;
  text-indent: 7px;
}

.am-input-container {
  width: 100%;
}

.am-right-form .react-tel-input {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 100%;

  .am-register-phoneNumber {
    border-radius: 0;
    text-align: left;
    padding-left: 5px;

    &::placeholder {
      text-indent: 10px;
      transform: none;
      text-align: left;
    }

    &.form-control.invalid-number {
      border: 0;
      background-color: $general;
    }
  }
}

.am-input-error,
.am-register-phoneNumber.am-input-error {
  border: 2px solid $danger !important;
  outline: none;
}

.am-register-input::placeholder,
.am-input::placeholder {
  font-family: Poppins, sans-serif;
  color: $on-general-sub;
  text-indent: 50%;
  transform: translate(-25%);
  text-align: center;
}

.am-input-code::placeholder {
  font-family: Poppins, sans-serif;
  color: $on-general-sub;
  text-indent: 50%;
  transform: translate(-25%);
}

.am-register-container,
.am-register-tel-container,
.am-input-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.am-register-container,
.am-input-container {
  overflow: hidden;
}

.am-register-container {
  width: 48%;
}

.am-right-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70%;
  height: 100%;
}

.am-checkbox {
  display: flex;
  margin-top: 10px;
}

.am-checkbox-label {
  text-align: left;
  color: $on-primary;
  line-height: 1.4;
  font-size: 12px;
}

.am-password-link {
  color: $accent;
  cursor: pointer;
  display: inline-block;
  position: relative;
  font-family: Inconsolata, monospace;
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  height: 1.25rem;

  &:after {
    content: "";
    width: 98%;
    position: absolute;
    left: 0;
    bottom: 1px;
    border-width: 0 0 1px;
    border-style: solid;
    color: $accent;
  }
}

.am-right-form input:not([type="checkbox"]) {
  text-align: center;
}

.am-right-form-edit .am-input:first-of-type ::placeholder {
  color: $accent;
}

.am-right-form-edit .am-input:last-child {
  align-self: center;
  margin-bottom: 20px;
}

.am-right-form-edit {
  width: 70%;
  padding-bottom: 2rem;

  @media only screen and (min-width: $tablet) {
    padding-bottom: unset;
  }
}

.am-checkbox-input {
  margin-left: -1px;
}

.am-passwords-link {
  display: flex;
  justify-content: flex-end;
  width: 98%;
  margin-top: 20px;
}

@media only screen and (max-width: $tablet) {
  .am-right-container {
    align-items: flex-start;
  }
}

@media only screen and (min-width: 300px) {
  .am-right-form-register {
    margin: unset;
    padding: unset;
    overflow-y: scroll;
  }

  .am-right-container {
    width: 100%;
    height: 70%;
    overflow-y: scroll;
  }

  .am-passwords-link {
    justify-content: center;
    margin-bottom: 20px;
  }

  .am-submit-button {
    width: 60%;
  }

  .react-tel-input .flag-dropdown {
    width: 17%;
  }

  .am-right-form .react-tel-input .am-register-phoneNumber {
    width: 80%;
  }
}

@media only screen and (max-width: $tablet) {
  .am-right-form-register {
    min-height: 400px;
  }
}

@media only screen and (min-width: 350px) and (max-height: 550px) {
  .am-passwords-link {
    margin-bottom: 0;
  }

  .am-right-container {
    height: 60%;
  }
}

@media screen and (min-width: $tablet) {
  .am-passwords-link {
    justify-content: flex-end;
  }

  .am-submit-button-container {
    width: calc(70% - 30px);
  }

  .am-right-form input:not([type="checkbox"]) {
    text-align: left;
  }

  .am-right-form .react-tel-input .am-register-phoneNumber {
    width: 86%;
  }

  .react-tel-input .flag-dropdown {
    width: 10%;
  }

  .am-error-message {
    text-align: right;
  }

  .am-register-required {
    text-align: right;
  }

  .am-input-edit:first-child {
    margin-top: 30px;
  }

  .am-register-input::placeholder,
  .am-input::placeholder {
    text-indent: 10px;
    transform: none;
    text-align: left;
  }

  .am-input-code::placeholder {
    text-indent: 10px;
    transform: none;
    text-align: left;
    color: $accent;
  }

  .am-right-container {
    width: 70%;
    height: auto;
    overflow-y: unset;
  }

  .am-register-input {
    width: 100%;
  }

  .am-register-container {
    width: 48%;
  }
}
