@use "../../theme/utils/variables.module.scss" as *;

.space-configurator-header {
  background-color: $primary;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: $HEADER_HEIGHT;
  padding: 0 0.75rem 0 1.5rem;
  font-size: $defaultFont;
  z-index: 3;

  @media screen and (max-width: $tablet) {
    font-size: $mediumFont;
  }
  border-bottom: 0.0625px solid $primary-border;
  .header-logo {
    height: 2.5rem;
    max-width: 12.5rem;
  }

  .header-left-side,
  .header-right-side {
    display: flex;
  }

  .header-left-side {
    align-items: center;
  }
}

.header-right-side,
.header-drawer {
  .tecma-button {
    background-color: transparent;

    path {
      fill: $on-general;
    }
  }
}
.tecma-drawer-container {
  .tecma-drawer {
    background-color: $general;
  }
  .header-drawer {
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    gap: 2rem 0;

    .close-drawer {
      margin-left: auto;
    }
  }
}
