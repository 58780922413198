@use "../../../../theme/utils/variables.module.scss" as *;

.recap-header {
  display: flex;
  flex-direction: column;
  background-color: $general;
  &.hasPriceDetails {
    &.isOpen {
      .recap-header__button {
        @media screen and (max-width: $desktop_M) {
          padding-bottom: 0.5rem;
        }
      }
    }
    .recap-header__button {
      @media screen and (max-width: $desktop_M) {
        padding-bottom: 0.5rem;
      }
    }
  }
  &__button {
    padding: 1.5rem;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    border: 0;
    background-color: $general;
    transition: all 1000ms;
    cursor: pointer;
    p {
      margin: 0;
      font-family: $primaryFont;
      font-size: $mediumFont;
      color: $accent;
      display: flex;
      align-items: center;
      transition: color 300ms;
    }
    .tecma-icon {
      margin-left: 0.5rem;
      transform: rotate(0);
      transition: transform 1s;
      path {
        transition: fill 300ms;
        fill: $accent;
      }
    }
    &.isOpen {
      .tecma-icon {
        transform: rotate(180deg);
      }
    }
    @media screen and (min-width: $desktop_S) {
      &:hover {
        p {
          color: $accent-hover;
        }
        .tecma-icon {
          path {
            fill: $accent-hover;
          }
        }
      }
    }
    @media screen and (max-width: $desktop_S) {
      border-left: 0;
      border-bottom-color: $general-border;
    }
    @media screen and (max-width: $tablet) {
      padding: 0;
      flex: 1;
      border-bottom: 0;
      justify-content: space-between;
      transition: all 1000ms;
      .tecma-icon {
        transition: all 1000ms;
        transform: rotate(180deg);
      }
      &.isOpen {
        .tecma-icon {
          transform: rotate(0deg);
        }
      }
    }
  }
  @media screen and (max-width: $desktop_M) {
    p {
      color: $on-general;
      display: block;
      text-align: left;
      span {
        color: $accent;
      }
    }
  }

  @media screen and (max-width: $tablet) {
    display: grid;
    grid-template-rows: 0fr;
    transition: grid-template-rows 1s, padding 1s;
    padding: 1.5rem;
    &.hasPriceDetails {
      padding: 1.5rem 1.5rem 0;
      &.isOpen {
        padding: 1rem 1.5rem;
      }
    }
    &.isOpen {
      min-height: unset;
      grid-template-rows: 1fr;
    }
  }
}
