@use "theme/utils/variables.module.scss" as *;

.planimetry-page-sheet {
  .page-sheet-container {
    display: flex;
    flex-direction: column;
  }
  .plan-img {
    @media screen and (max-width: $desktop_S) {
      flex: 1;
      height: 100%;
      width: auto;
    }
  }

  .planimetry-image-container {
    display: flex;
    overflow: hidden;
    justify-content: center;
    flex: 1;

    .plan-img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    @media screen and (max-width: $desktop_S) {
      flex: 2;
      .plan-img {
        object-fit: contain;
        margin: auto;
      }
    }
  }

  .spinner {
    display: "inline-flex";
    justify-content: "center";
    align-items: "center";
  }

  .planimetry-options {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    .planimetry-zoom {
      .tecma-icon path {
        fill: $general;
      }
      background-color: $accent;
      align-self: center;
      margin-left: 1rem;

      @media screen and (max-width: $desktop_L) {
        margin-left: 0;
      }
    }

    .details {
      display: flex;
      flex-direction: column;
      align-self: flex-end;
      .details-title {
        font-family: $secondaryFontLight;
        color: $on-general-sub;
        text-transform: uppercase;
      }
      .details-description {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        text-transform: uppercase;
        font-size: $smallFont;
        font-family: $secondaryFont;
        color: $on-general;

        @media screen and (max-width: $desktop_S) {
          flex-direction: column;
        }
      }
      .details-divider {
        height: 1rem;
        width: 0.125rem;
        background-color: $on-general;
        margin: 0 0.5rem;

        @media screen and (max-width: $desktop_S) {
          visibility: hidden;
        }
      }
      @media screen and (max-width: $desktop_S) {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-self: unset;

        .details-title {
          margin-top: 0;
        }
      }
    }

    @media screen and (max-width: $desktop_L) {
      flex-direction: column;
      align-content: flex-start;
      .planimetry-zoom {
        align-self: flex-start;
      }
    }
  }

  @media screen and (max-width: $tablet) {
    &.page-sheet {
      margin-top: 0;
      height: 100%;
      padding: 0;
    }
    .page-sheet-container {
      padding: 0;
      height: 100%;
    }
    .logo-container {
      display: none;
    }
    .planimetry-zoom {
      margin: 1rem;
    }
    .details {
      display: none;
    }
  }

  &.isZoomed {
    background-color: $general;

    .page-sheet-description {
      .page-sheet-title {
        display: flex;
        justify-content: center;
        font-size: $extraLargeFont;

        .planimetry-title {
          display: flex;
          height: 3rem;
          padding: 0 0.5rem;
          border: solid 2px $accent;
          justify-content: space-evenly;
          align-items: center;
          margin: 1rem 0;

          .tecma-icon {
            align-self: center;
          }
        }
      }
    }

    .page-sheet-container {
      padding: 0;
      .react-transform-wrapper {
        height: 100%;
      }
    }
    .planimetry-options {
      .planimetry-zoom {
        .tecma-icon {
          width: 2rem;
          height: 2rem;
          path {
            fill: $accent;
          }
        }
        background-color: $general;
        color: $accent;
        position: absolute;
        font-size: 16px;
        top: 1rem;
        right: 1rem;

        @media screen and (max-width: $tablet) {
          top: 0.438rem;
          right: 0;
        }
      }
      .details {
        display: none;
      }
    }
  }
}

.page-content-body {
  &.is-shrinked {
    @media screen and (max-width: $tablet) {
      .planimetry-image-container {
        .plan-img {
          object-fit: cover;
        }
      }
    }
  }
}

@media screen and (max-width: $desktop_S) {
  .planimetry-page-sheet {
    display: flex;
    width: 100%;
    height: calc(100% - 2.75rem);
    margin-top: 3.5rem;
    align-items: stretch;
    justify-content: center;
    .page-sheet-container {
      flex-direction: row;
      background-color: $general;

      .planimetry-options {
        flex-direction: column-reverse;
        .planimetry-zoom {
          align-self: flex-end;

          .tecma-icon {
            transform: scale(1.5);
          }
        }
      }
    }

    &.isZoomed {
      height: 100%;
      margin: 0;
      justify-content: flex-start;
    }
  }
}
