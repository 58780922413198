@use "theme/utils/variables.module.scss" as *;

.space-configurator-packsPageContent {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 3rem;
  column-gap: 1.5rem;
  @media screen and (max-width: $desktop_S) {
    padding: 1rem 0;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto;
    justify-content: unset;
  }
}
