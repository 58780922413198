@use "../../../../theme/utils/variables.module.scss" as *;

$recap-transition-time: 1000ms;

.tecma-button.confirm-button-mobile {
  order: 1;
  background-color: $accent;
  outline-color: $on-accent;
  color: $on-accent;
  transition: all $recap-transition-time;

  &.disabled {
    &.outlined {
      background-color: $general;
      color: $on-general-disabled;
      outline-color: $on-general-disabled;
    }
    .tecma-icon {
      path {
        fill: $on-general-disabled;
      }
    }
    &:hover {
      color: $on-general-disabled;
      outline-color: $on-general-disabled;
    }
  }

  &.is-selected {
    background-color: $accent-alternative;
    outline-color: $on-accent;
    color: $on-accent;
    .tecma-icon {
      path {
        fill: $on-accent;
      }
    }
  }
  &.is-finish {
    background-color: $accent-alternative;
    outline-color: $on-accent;
    color: $on-accent;
  }

  &.is-loading {
    background-color: $accent-alternative;
    outline-color: $on-accent;
    color: $on-accent;
    .tecma-icon {
      path {
        fill: $on-accent;
      }
    }
  }

  strong {
    line-height: 1;
  }
}
