// colors/main
$accent: #6266ef;
$accent-active: #3a3c92;
$accent-border: #6266ef;
$accent-hover: #4e51c0;
$accent-light: #f6f8ff;

$on-accent: #ffffff;
$on-accent-disabled: #9e9e9e;
$on-accent-light: #474747;
$on-accent-sub: #e0e0e0;

$primary: #b1b9cd;
$primary-active: #8991a6;
$primary-border: #ced4e6;
$primary-hover: #ced4e6;
$primary-light: #ebedf8;

$on-primary: #3b3b3b;
$on-primary-disabled: #9e9e9e;
$on-primary-light: #474747;
$on-primary-sub: #5e5e5e;

$secondary: #8a96f8;
$secondary-active: #6266ef;
$secondary-border: #7d87f5;
$secondary-hover: #7d87f5;
$secondary-light: #f6f8ff;

$on-secondary: #ffffff;
$on-secondary-disabled: #b9b9b9;
$on-secondary-light: #474747;
$on-secondary-sub: #e0e0e0;

$tertiary: #6266ef;
$tertiary-active: #3a3c92;
$tertiary-border: #4e51c0;
$tertiary-hover: #4e51c0;
$tertiary-light: #f6f8ff;

$on-tertiary: #ffffff;
$on-tertiary-disabled: #b9b9b9;
$on-tertiary-light: #474747;
$on-tertiary-sub: #e0e0e0;

// colors/neutral

$disabled: #f1f1f1;
$on-disabled: #b9b9b9;

$general: #ffffff;
$general-active: #f1f1f1;
$general-border: #e0e0e0;
$general-hover: #f8f8f8;
$on-general: #3b3b3b;
$on-general-disabled: #b9b9b9;
$on-general-sub: #767676;

$inverse: #ffffff;
$inverse-active: #e0e0e0;
$inverse-border: #f1f1f1;
$inverse-hover: #f1f1f1;
$on-inverse: #3b3b3b;
$on-inverse-disabled: #767676;
$on-inverse-sub: #5e5e5e;

$negative: #767676;
$negative-active: #3b3b3b;
$negative-border: #3b3b3b;
$negative-hover: #909090;
$on-negative: #ffffff;
$on-negative-disabled: #9e9e9e;
$on-negative-sub: #e0e0e0;

// colors/semantic/danger

$danger: #ca4a46;
$danger-active: #7e2a28;
$danger-border: #ca4a46;
$danger-hover: #a33b38;
$danger-light: #feedeb;
$on-danger: #ffffff;

// colors/semantic/info

$info: #4073d5;
$info-active: #1a4390;
$info-border: #4073d5;
$info-hover: #2b64d1;
$info-light: #eaf1fe;
$on-info: #ffffff;

// colors/semantic/success

$success: #2e872b;
$success-active: #015300;
$success-border: #2e872b;
$success-hover: #007c00;
$success-light: #ebf3ea;
$on-success: #ffffff;

// colors/semantic/warning

$warning: #a46a1d;
$warning-active: #663d00;
$warning-border: #a46a1d;
$warning-hover: #875200;
$warning-light: #f7f0e9;
$on-warning: #ffffff;

$accent-alternative: #0db392;
$accent-alternative-light: #20efc6;
$accent-alternative-dark: #086d59;

//Token globali - Colori fissi
$gray-10: #1a1a1a;
$gray-20: #333333;
$gray-30: #4d4d4d;
$gray-40: #666666;
$gray-50: #808080;
$gray-60: #999999;
$gray-70: #b3b3b3;
$gray-75: #bfbfbf;
$gray-80: #cccccc;
$gray-85: #d9d9d9;
$gray-90: #e6e6e6;
$gray-95: #f2f2f2;

$white: #ffffff;
$black: #000000;

$blanket-overlay: #00000066;

// values/border-radius

$border-radius-element: 2px;
$border-radius-external: 2px;
$border-radius-internal: 1px;
$border-radius-standard: 1px;

// values/border-width

$border-width-element: 0;
$border-width-external: 1px;
$border-width-internal: 0;
$border-width-standard: 0;

// font size 1rem === 16px
$extraSmallFont: 0.625rem; //10px
$smallFont: 0.76rem; //12px
$defaultFont: 0.875rem; // 14px
$mediumFont: 1rem; //16px
$largeFont: 1.25rem; //20px
$extraLargeFont: 1.5rem; //24px

// font family
@font-face {
  font-family: PoppinsLight;
  src: local("Poppins"),
    url(https://fonts.gstatic.com/s/poppins/v15/pxiEyp8kv8JHgFVrJJfecnFHGPc.woff2)
      format("woff2");
  font-weight: 200;
}
@font-face {
  font-family: Poppins;
  src: local("Poppins"),
    url(https://fonts.gstatic.com/s/poppins/v15/pxiEyp8kv8JHgFVrJJfecnFHGPc.woff2)
      format("woff2");
  font-weight: 400;
}
@font-face {
  font-family: PoppinsRegular;
  src: local("Poppins"),
    url(https://fonts.gstatic.com/s/poppins/v15/pxiEyp8kv8JHgFVrJJfecnFHGPc.woff2)
      format("woff2");
  font-weight: 500;
}
@font-face {
  font-family: PoppinsBold;
  src: local("Poppins"),
    url(https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLEj6Z1xlFd2JQEk.woff2)
      format("woff2");
  font-weight: 600;
}
@font-face {
  font-family: PoppinsBolder;
  src: local("Poppins"),
    url(https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLCz7Z1xlFd2JQEk.woff2)
      format("woff2");
  font-weight: 700;
}

@font-face {
  font-family: InconsolataLight;
  src: local("Inconsolata"),
    url(https://fonts.gstatic.com/s/inconsolata/v21/QlddNThLqRwH-OJ1UHjlKENVzkWGVkL3GZQmAwLyya15IDhunA.woff2)
      format("woff2");
  font-weight: 200;
}
@font-face {
  font-family: Inconsolata;
  src: local("Inconsolata"),
    url(https://fonts.gstatic.com/s/inconsolata/v21/QlddNThLqRwH-OJ1UHjlKENVzkWGVkL3GZQmAwLyya15IDhunA.woff2)
      format("woff2");
  font-weight: 400;
}
@font-face {
  font-family: InconsolataRegular;
  src: local("Inconsolata"),
    url(https://fonts.gstatic.com/s/inconsolata/v21/QlddNThLqRwH-OJ1UHjlKENVzkWGVkL3GZQmAwLyya15IDhunA.woff2)
      format("woff2");
  font-weight: 500;
}
@font-face {
  font-family: InconsolataBold;
  src: local("Inconsolata"),
    url(https://fonts.gstatic.com/s/inconsolata/v21/QlddNThLqRwH-OJ1UHjlKENVzkWGVkL3GZQmAwLyya15IDhunA.woff2)
      format("woff2");
  font-weight: 600;
}
@font-face {
  font-family: InconsolataBolder;
  src: local("Inconsolata"),
    url(https://fonts.gstatic.com/s/inconsolata/v21/QlddNThLqRwH-OJ1UHjlKENVzkWGVkL3GZQmAwLyya15IDhunA.woff2)
      format("woff2");
  font-weight: 700;
}

$primaryFontLight: PoppinsLight, sans-serif;
$primaryFont: Poppins, sans-serif;
$primaryFontRegular: PoppinsRegular, sans-serif;
$primaryFontBold: PoppinsBold, sans-serif;
$primaryFontBolder: PoppinsBolder, sans-serif;

$secondaryFontLight: InconsolataLight, monospace;
$secondaryFont: Inconsolata, monospace;
$secondaryFontRegular: InconsolataRegular, monospace;
$secondaryFontBold: InconsolataBold, monospace;
$secondaryFontBolder: InconsolataBolder, monospace;
