@use "../../../../theme/utils/variables.module.scss" as *;

.planimetry-item {
  display: flex;
  height: 3rem;
  width: 17.875rem;
  border: solid 2px transparent;
  background-color: $secondary-light;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
  color: $on-secondary-light;
  transition: border-color 0.3s linear, background-color 0.3s linear;

  @media only screen and (max-width: $tablet) {
    flex-direction: column;
    height: auto;
    width: 8rem;
    min-height: 5.625rem;
    min-width: 8rem;
    gap: 0.5rem 0;
    padding: 0.625rem;
  }

  &:hover {
    background-color: $secondary-hover;
    .tecma-icon path {
      fill: $on-secondary;
    }
  }

  &-icon {
    flex: 1;

    @media only screen and (max-width: $tablet) {
      flex: auto;
      min-width: 1.5rem;
      min-height: 1.5rem;
    }
  }

  &-divider {
    height: 80%;
    width: 0.125rem;
    background-color: $secondary-border;
    transition: background-color 300ms linear;

    @media only screen and (max-width: $tablet) {
      width: 5.25rem;
      height: 0.125rem;
    }
  }

  &-name {
    flex: 1 1 55%;
    padding-left: 1rem;
    font-family: $primaryFont;
    font-size: 1rem;
    text-align: left;
    color: $on-secondary-light;

    @media only screen and (max-width: $tablet) {
      padding: 0;
      text-align: center;
    }
  }

  &.active {
    border-color: $accent-border;
    .planimetry-item-divider {
      background-color: $accent-border;
    }

    &.confirmed {
      border-color: $accent-alternative;
      .planimetry-item-divider {
        background-color: $accent-alternative;
      }
    }
  }

  &.disabled {
    cursor: not-allowed;
    background-color: $disabled;
    border: 1px solid $disabled;

    .planimetry-item-name {
      color: $on-disabled;
    }

    .planimetry-item-color {
      background-color: $secondary-light !important;
      border-color: transparent;
    }
  }
}
