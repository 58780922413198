@use "theme/utils/variables.module.scss" as *;

.single-pack {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.5rem 0;
  border: 0.0625rem solid $general-border;
  background-color: $general;
  flex-wrap: wrap;

  @media only screen and (min-width: $desktop_S) {
    padding: 1.875rem 1.25rem;
    flex-direction: row;
  }

  p {
    margin: 0;
    font-family: $primaryFont;
    font-size: $mediumFont;
    line-height: 1.25rem;

    span {
      font-family: $primaryFontBold;
    }
  }

  &-name {
    text-transform: uppercase;
    color: $on-general;
  }

  &-starting-price {
    color: $on-general-sub;

    span {
      color: $accent;
    }
  }

  &-included {
    color: $accent;
    text-transform: uppercase;
  }
}
