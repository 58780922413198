@use "theme/utils/variables.module.scss" as *;

.space-configurator-space-info {
  padding: 1rem 1.5rem;
  box-sizing: border-box;
  flex-direction: column;
  display: flex;
  border: 0.0625rem solid $general-border;
  justify-content: center;
  width: 100%;
  border-radius: 0.125rem;

  @media only screen and (max-width: $desktop_S) {
    position: absolute;
    z-index: 1;
    top: 5rem;
    height: 2.5rem;
    width: 23.5rem;
    background-color: $general;
  }

  .multi-building {
    margin-bottom: 1rem;
    p {
      margin: 0;
    }
    .building-name {
      text-transform: uppercase;
      font-family: $primaryFontBolder;
      margin-top: 0;
    }
    .change-building {
      cursor: pointer;
      font-family: $primaryFontBolder;
      text-transform: uppercase;
      text-decoration: underline;
      color: $accent;
    }
    .current-configuration-cleared {
      color: $accent;
      font-family: $primaryFont;
      font-size: $smallFont;
    }
  }

  .container {
    .title {
      margin: 0;
      font-family: $primaryFontBolder;
      font-size: $largeFont;
      color: $on-general;
      span {
        font-weight: 500;
        font-family: $primaryFont;
      }
    }
    .subtitle {
      margin: 0;
      font-size: $mediumFont;
      font-family: $primaryFont;
      color: $on-general;
    }
  }
}
