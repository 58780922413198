@use "theme/utils/variables.module.scss" as *;

.space-configurator-recapSection {
  position: absolute;
  top: 0;
  right: 0;
  height: 0;
  display: flex;
  width: 18.5rem;
  flex-direction: column;
  z-index: 2;
  transition: height 1s;

  &.is-mobile {
    position: fixed;
    z-index: 2;
    -webkit-box-shadow: 0px -4px 16px rgba(105, 105, 105, 0.2);
    -moz-box-shadow: 0px -4px 16px rgba(105, 105, 105, 0.2);
    box-shadow: 0px -4px 16px rgba(105, 105, 105, 0.2);
    transition: box-shadow 1000ms;
    max-height: calc($DEVICE_VIEW_HEIGHT - $HEADER_HEIGHT);
    height: auto;

    &.isOpen {
      -webkit-box-shadow: 0px -4px 16px rgba(105, 105, 105, 0);
      -moz-box-shadow: 0px -4px 16px rgba(105, 105, 105, 0);
      box-shadow: 0px -4px 16px rgba(105, 105, 105, 0);
    }
  }

  @media screen and (min-width: $tablet) {
    &.isOpen {
      height: 100%;
    }
  }

  @media screen and (min-width: $desktop_S) and (max-width: $desktop_M) {
    transition: height 3s;
    &.isOpen {
      transition: height 1s;
    }
  }
  @media screen and (min-width: $tablet) and (max-width: $desktop_S) {
    position: relative;
    top: 0;
    height: 100%;
    box-shadow: 0 0 1rem rgba(166, 166, 166, 0.3);
    &.isOpen {
      height: 100%;
    }
  }
  @media screen and (max-width: $tablet) {
    position: sticky;
    height: fit-content;
    bottom: 0;
    top: auto;
    margin: 0;
    width: 100%;
  }
}
