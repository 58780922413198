@use "theme/utils/variables.module.scss" as *;

.logged-user-mobile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  .logged-user-greetings {
    margin: 0 0 2rem 0.5rem;
    font-family: $primaryFont;
    color: $accent;
    font-size: $largeFont;
    span {
      font-size: $mediumFont;
    }
  }
  .tecma-button {
    font-size: $mediumFont;
    border-radius: 0.125rem;
    justify-content: flex-start;
    width: 100%;
  }
}

.logged-user-btn {
  p {
    max-width: 10.5rem;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    font-family: $secondaryFontBolder;
    color: $on-primary;

    span {
      font-family: $secondaryFont;
    }
  }

  .tecma-icon {
    transition: transform 0.5s ease;
    &.open {
      transform: rotate(180deg);
    }
  }
  &.impersonated {
    p {
      font-family: $secondaryFont;
      span {
        font-family: $secondaryFontBolder;
      }
    }
  }
}

.logged-user-dropdown {
  ul {
    background-color: $general;
    box-shadow: 0px 45px 41px rgba(166, 166, 166, 0.05),
      0px 29.1667px 24.0116px rgba(166, 166, 166, 0.037963),
      0px 17.3333px 13.0593px rgba(166, 166, 166, 0.0303704),
      0px 9px 6.6625px rgba(166, 166, 166, 0.025),
      0px 3.66667px 3.34074px rgba(166, 166, 166, 0.0196296),
      0px 0.833333px 1.61343px rgba(166, 166, 166, 0.012037);

    li {
      padding: 0.25rem 0.5rem;
      .tecma-button {
        color: $on-general;
        justify-content: flex-start;
        padding: 0 1rem;
        font-family: $secondaryFontBolder;
        gap: 0 0.5rem;

        &:hover {
          background-color: $general-hover;
        }

        .tecma-icon path {
          fill: $on-general;
        }
      }
    }

    .tecma-dropDown-divider {
      margin: 0 0 0 1.5rem;
      width: calc(100% - 3rem);
      background-color: $general-border;
    }
  }
}
