@use "theme/utils/variables.module.scss" as *;

.space-configurator-mobileModal {
  display: flex;
  flex-direction: column;
  height: calc(100% - #{$HEADER_HEIGHT});
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: $general;
  z-index: 11;

  .modal-header {
    padding: 1.5rem 1rem;
    background-color: $gray-95;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 0;

    &-description {
      font-family: $primaryFont;
      max-width: 16rem;
      .title {
        font-size: $mediumFont;
        font-weight: 500;
        margin: 0 0 0.125rem;
        color: $gray-20;
      }
      .subtitle {
        font-size: $defaultFont;
        color: $gray-30;
      }
    }

    .tecma-button {
      background-color: $accent;
      border-radius: 100%;

      .tecma-icon {
        path {
          fill: $general;
        }
      }
    }
  }

  .modal-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    min-height: 0;
    flex: 1;
  }
}
