@use "theme/utils/variables.module.scss" as *;

.price-detail-item {
  display: flex;
  flex-direction: row;
  font-size: 12px;
  font-family: $secondaryFontRegular;
  justify-content: space-between;
  align-items: flex-end;
  text-transform: uppercase;
  margin: 0;
  padding: 0.5rem;
  &:not(:first-of-type) {
    padding-top: 0;
  }

  &__name {
    margin: 0;
  }

  &__price {
    margin: 0;
    margin-left: 0.5rem;
  }
}
