@use "theme/utils/variables.module.scss" as *;

.space-configurator-carousel {
  height: 100%;
  .slider-wrapper {
    height: 100%;

    .react-transform-wrapper {
      height: 100%;
      width: 100%;

      .react-transform-component {
        height: 100%;

        &.aspect-ratio-horizontal {
          .tecma-lazyImage {
            height: 100%;
          }
        }

        &.aspect-ratio-vertical {
          height: unset;
          width: 100%;

          .tecma-lazyImage {
            width: 100%;
          }
        }
      }
    }
  }
  .carousel-buttons-container {
    justify-content: flex-start;
    padding: 1.5rem;
    bottom: 0;
    .tecma-button {
      .tecma-icon path {
        fill: $on-negative;
      }
      &:not(.disabled, .zoom) {
        background-color: $negative;
        &:hover {
          background-color: $negative-hover;
        }
        &.zoom {
          background-color: $accent;
        }
      }
      &.zoom {
        background-color: $accent;
        &:hover {
          background-color: $accent-hover;
        }
      }
    }
    svg {
      transform: scale(1.5);
    }
    @media only screen and (max-width: $desktop_S) {
      height: 100%;
      pointer-events: none;
      .tecma-button {
        pointer-events: initial;
        position: absolute;
        &.next,
        &.prev {
          display: none;
        }
        &.zoom {
          right: 1rem;
        }
        &.zoom {
          bottom: 1rem;
          z-index: 1;
        }
      }
    }
    @media only screen and (max-width: $tablet) {
      .tecma-button {
        width: 2.5rem;
        height: 2.5rem;
      }
    }
  }
  &.isZoomed {
    .carousel-buttons-container {
      @media only screen and (max-width: $desktop_S) {
        gap: 0 1.5rem;
        justify-content: center;
        align-items: flex-end;
        padding-bottom: 3rem;
        .tecma-button {
          width: 3rem;
          height: 3rem;
          .tecma-icon {
            width: 1.5rem;
            height: 1.5rem;
          }
          &.next,
          &.prev {
            display: initial;
            position: relative;
            inset: initial;
            transform: translateY(0);
          }
          &.zoom {
            top: 1.5rem;
            right: 1.25rem;
          }
        }
      }
      @media only screen and (max-width: $tablet) {
        gap: 0 2.5rem;
        padding-bottom: 2rem;
        .tecma-button {
          width: 2.5rem;
          height: 2.5rem;
          .tecma-icon {
            width: 1.25rem;
            height: 1.25rem;
          }
          &.zoom {
            top: 1rem;
            right: 0.5rem;
            .tecma-icon path {
              stroke: initial;
            }
          }
        }
      }
    }
  }
  .control-dots {
    margin-bottom: 1.5rem;
    z-index: 0;
    .dot {
      margin: 0 0.375rem 0 0;
    }
  }
}
