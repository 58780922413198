@use "theme/utils/variables.module.scss" as *;

.space-configurator-datePicker {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 1rem;
  margin-top: 1.75rem;
  padding-top: 1.5rem;
  border-top: 0.0625rem solid $general-border;

  @media screen and (max-width: $desktop_M) {
    margin-top: 0;
    border-top: none;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
  }

  @media screen and (max-width: $desktop_S) {
    flex-direction: row;
    gap: 1rem;
  }

  @media screen and (max-width: $tablet) {
    flex-direction: column;
  }

  .datePicker-container {
    @media screen and (max-width: $desktop_M) {
      width: 100%;

      .spaceConfigurator-picker {
        width: unset;
        flex: 1;
        height: 4rem;
        &:first-child {
          flex: 2;
        }
        .picker-value-container {
          flex: 1;
        }
      }
    }

    @media screen and (max-width: $desktop_S) {
      width: unset;
      flex: 1;
    }

    @media screen and (max-width: $tablet) {
      width: 100%;

      .spaceConfigurator-picker {
        width: unset;
        flex: 1;
        &:first-child {
          flex: 2;
        }

        .picker-value-container {
          flex: 1;
        }
      }
    }
    .datePicker-label {
      margin: 0;
      margin-bottom: 0.5rem;
      font-family: $secondaryFont;
      font-size: $defaultFont;
      font-weight: 600;
      color: $on-general-sub;
    }
    .datePicker-content {
      display: flex;
      .spaceConfigurator-picker {
        + .spaceConfigurator-picker {
          margin-left: 0.5rem;
        }

        &:first-child .picker-value-container {
          width: 7rem;
        }
      }
    }
  }
}
