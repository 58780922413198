@use "theme/utils/variables.module.scss" as *;

.spaceConfigurator-headerMenu {
  display: flex;

  @media screen and (max-width: $tablet) {
    flex-direction: column-reverse;
    gap: 1rem;
  }

  .element-container {
    display: flex;
    align-items: center;
    max-height: 3.125rem;
    &:not(:last-child) {
      border-right: 0.07rem solid $primary-border;
    }

    .tecma-button,
    a {
      margin: 0 0.5rem;
      padding: 0 1rem;
      height: 3.125rem;
      transition: background-color 0.3s ease;
      &:hover {
        background-color: $primary-hover;
      }
    }

    .logged-user {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 1rem;
      margin: 0 0.5rem;
      gap: 0 1rem;

      p {
        max-width: 10.5rem;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        -webkit-box-orient: vertical;
        font-family: $secondaryFont;
        color: $on-primary;
        font-size: $mediumFont;

        span {
          font-family: $secondaryFontBolder;
        }
      }
      .tecma-icon {
        path {
          fill: $on-primary;
        }
      }
    }

    @media screen and (max-width: $tablet) {
      padding: 0;
      max-height: fit-content;
      &:not(:last-child) {
        border-right: 0;
      }
      .tecma-button,
      a {
        padding-left: 0.5rem;
        margin: 0;
        height: 2.5rem;
      }
    }
  }

  > *:last-child {
    margin: unset;
  }

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    border-radius: 0.125rem;
    height: 2.5rem;
  }

  .tecma-button,
  a {
    color: $on-primary;
    font-size: 1rem;
    font-family: $secondaryFontBolder;
    display: flex;
    gap: 0 1rem;
    @media screen and (max-width: $tablet) {
      gap: 0 0.5rem;
    }

    .tecma-icon {
      @media screen and (max-width: $tablet) {
        width: 1.5rem;
        height: 1.5rem;
      }
      path {
        fill: $on-primary;
      }
    }
  }
}
