@use "../../../theme/utils/variables.module.scss" as *;

.space-configurator-mood-step {
  font-family: $primaryFont;
  padding: 1.5rem;
  @media only screen and (max-width: $tablet) {
    padding: 1rem 0 1.5rem;
    .space-configurator-info-message {
      padding: 0 1rem;
    }
  }

  .mood-picker-container {
    margin: 2rem 0;
  }
}
