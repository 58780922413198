@use "theme/utils/variables.module.scss" as *;

.first-date-popup {
  display: flex;
  flex-direction: column;
  align-items: center;

  &.tecma-modal {
    background-color: $general;
  }

  &-header {
    align-self: flex-end;
    .tecma-icon path {
      fill: $accent;
    }
  }
  &-divider {
    height: 0.063rem;
    width: 100%;
    max-width: 37rem;
    margin-bottom: 1.5rem;
    background-color: $general-border;
  }
  &-content {
    text-align: center;
    font-family: $primaryFont;
    line-height: 0.7rem;
    color: $on-general;

    span {
      color: $accent;
      font-family: $primaryFontBold;
    }

    p {
      strong {
        color: $accent;
      }
    }

    @media screen and (max-width: $tablet) {
      line-height: initial;
    }
  }
  &-date {
    margin-top: 1.5rem;
    font-size: $extraLargeFont;
    text-transform: uppercase;
    color: $accent;
    font-weight: 700;
    align-items: center;
    margin-bottom: 1.5rem;
  }
  &-footer {
    padding-top: 2.5rem;
    padding-bottom: 2rem;
  }
  &-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    .tecma-icon path {
      fill: $accent;
    }
    &-text {
      text-transform: uppercase;
      font-family: $primaryFontBold;
      font-size: $largeFont;
      color: $accent;
      margin-top: 0;
      margin-bottom: 2.5rem;
    }
  }
  &-button.tecma-button {
    padding: 1.5rem;
    font-family: $primaryFont;
    &.confirm {
      color: $on-general;
      border-color: $on-general;
      &:hover {
        background-color: $general-hover;
      }
    }
  }
}

.tecma-modal-container {
  .tecma-modal {
    max-width: calc(100vw - 2rem);
  }
  .tecma-modal-content {
    overflow: inherit;
  }
}
