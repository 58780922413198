// @import "../../../../theme/utils/variables.module.scss";
@use "theme/utils/variables.module.scss" as *;

.am-left-container {
  padding: 0 30px;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 10px;
}

.am-left-header {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.am-left-title {
  color: $accent;
  position: relative;
  font-family: Inconsolata, monospace;
  font-size: $defaultFont;
  text-align: left;
  &::before {
    content: "";
    width: 110%;
    position: absolute;
    left: 0;
    top: -10px;
    border-width: 0 0 1px;
    border-style: solid;
    color: $accent;
  }
}

.am-left-subtitle {
  color: $on-primary;
  font-size: 18px;
  margin: 20px 0;
  display: inline-block;
  line-height: 1.4;
  align-self: center;
}

.am-subtile-bold {
  font-weight: bold;
  display: inline;
}
.am-subtile-normal {
  display: inline;
}

.am-left-footer {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-family: Inconsolata, monospace;
  margin-bottom: 20px;
  font-size: $defaultFont;
}

.am-left-footer-title {
  color: $on-primary;
}

.am-left-footer-action {
  color: $accent;
  cursor: pointer;
  position: relative;
  font-family: Inconsolata, monospace;
  font-size: $defaultFont;
  margin-left: 5px;
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  height: 2rem;
  text-align: left;
  text-decoration: underline;
  text-underline-position: under;
}

@media only screen and (min-width: 350px) {
  .am-left-header {
    margin-top: 40px;
  }
  .am-left-footer {
    margin-bottom: 20px;
    align-items: center;
  }
  .am-left-subtitle {
    font-size: 18px;
  }
}

@media only screen and (min-width: 300px) {
  .am-left-container {
    height: 30%;
  }
  .am-left-header {
    margin-top: 10px;
    align-items: center;
  }
  .am-left-footer {
    margin-bottom: 0;
  }
  .am-left-subtitle {
    font-size: 15px;
  }
  .am-left-title::before {
    width: 100%;
  }
}

@media only screen and (min-width: 350px) and (max-height: 550px) {
  .am-left-container {
    height: 40%;
  }

  .am-left-subtitle {
    margin-top: 0;
  }
}

@media screen and (min-width: $tablet) {
  .am-left-header {
    align-items: flex-start;
  }
  .am-left-footer {
    text-align: left;
    display: inline;
    &-action {
      margin-left: 0;
    }
  }
  .am-left-subtitle {
    text-align: left;
    align-self: flex-start;
  }
  .am-left-container {
    display: flex;
    border-right: 1px solid $secondary;
    width: 30%;
    height: initial;
    justify-content: space-between;
    margin-bottom: 0;
  }
}
