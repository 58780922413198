@use "theme/utils/variables.module.scss";
@import "theme/utils/utilities.scss";

.space-configurator-scaffold {
  // keep 100% height for Chrome bug on android
  // see https://bugs.chromium.org/p/chromium/issues/detail?id=844848#c4
  height: 100%;
  display: flex;
  overflow: hidden;
  &.app-is-loading {
    align-items: center;
    justify-content: center;
  }
}
