@use "theme/utils/variables.module.scss" as *;

.space-configurator-datePickerSection {
  margin-top: 1rem;
  background-color: $general;

  &.isOpen {
    .date-picker-section-recap .arrow {
      position: absolute;
      right: 0;
      margin-right: 1rem;
      transform: rotate(180deg);
      transition: transform 1s;
    }
  }

  .date-picker-section-recap {
    display: flex;
    position: relative;
    background: $general;
    color: $on-general;
    height: $DATE_PICKER_HEIGHT;
    font-size: $mediumFont;
    justify-content: flex-start;
    width: 100%;
    span {
      font-family: $primaryFontBold;
      white-space: nowrap;
    }

    .tecma-icon,
    path {
      fill: $accent;
    }

    .arrow {
      position: absolute;
      right: 0;
      margin-right: 1rem;
      transition: transform 1s;

      @media screen and (max-width: $tablet) {
        position: absolute;
        margin-right: 1rem;
      }
      @media screen and (min-width: $tablet) and (max-width: $desktop_S) {
        transition: transform 1s 1s;
      }
    }
  }

  .date-picker-section-container {
    .availability-info {
      margin: 1.25rem 0 0;
      color: $accent;
      font-family: $secondaryFontBolder;
      font-size: $defaultFont;
      display: flex;
      align-items: center;
      white-space: pre-wrap;

      &.manyApartments {
        color: $on-general;
        .tecma-icon {
          path {
            fill: $on-general;
          }
        }
      }

      @media screen and (max-width: $desktop_M) {
        align-items: flex-start;
      }

      @media screen and (max-width: $tablet) {
        margin: 1.5rem 0 0.5rem;
      }

      span {
        font-family: $secondaryFont;
      }

      .tecma-icon {
        margin-right: 0.5rem;
        &.availability-loader {
          margin: 0 0 0 0.5rem;
        }
        path {
          fill: $accent;
        }
      }
    }
  }
}

.date-picker-floating-open-background {
  background-color: $general;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 2;
}

@media screen and (max-width: $desktop_M) {
  .space-configurator-datePickerSection {
    box-shadow: 0px -4px 16px rgba(217, 217, 217, 0.2);
    .date-picker-section-container {
      .space-configurator-datePicker {
        padding: 0;
      }
      max-height: 0;
      padding: 0 1rem;
      overflow: hidden;
      transition: max-height 1s, padding-top 1s 0.25s, padding-bottom 1s 0.25s;

      &.isOpen {
        padding-top: 1rem;
        padding-bottom: 1rem;
        max-height: 20rem;
        transition: max-height 1s, padding-top 1s, padding-bottom 1s;
      }
    }
  }
}

@media screen and (max-width: $desktop_S) {
  .space-configurator-datePickerSection {
    position: absolute;
    top: 0;
    align-self: center;
    width: 45%;
    transition: width 1s 1s;
    flex-direction: column;
    &.isOpen {
      width: 90%;
      transition: width 1s;
    }

    .date-picker-section-container {
      &.isOpen {
        transition: max-height 1s 1s, padding-top 1s 1s, padding-bottom 1s 1s;
      }
    }
  }
}

@media screen and (max-width: $tablet) {
  .space-configurator-datePickerSection {
    margin: 0;
    width: 100%;
    &.isOpen {
      z-index: 3;
      width: 100%;
    }
    .date-picker-section-container {
      &.isOpen {
        max-height: 80rem;
        transition: max-height 1s, padding-top 1s, padding-bottom 1s;
      }
    }
  }
}
