@use "theme/utils/variables.module.scss" as *;

.page-sheet {
  padding: 1.656rem 1.5rem;
  display: flex;
  flex-flow: column;
  height: 100%;

  @media screen and (max-width: $desktop_S) {
    justify-content: flex-end;
    &.packs-page-sheet {
      padding: 1.656rem 0 0;
      .page-sheet-container {
        padding: 0;
      }
    }
  }

  &-description {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
  }

  &-title {
    margin: 0;
    font-family: $primaryFont;
    color: $gray-10;
  }

  &-subtitle {
    font-family: $primaryFontLight;
    font-size: $smallFont;
    color: $gray-10;
  }

  &-container {
    background-color: $general;
    padding: 1rem;
    max-width: calc(100% - 18.5rem); // calculated based on recap section width
    overflow: hidden;
    height: 100%;

    @media screen and (max-width: $desktop_S) {
      display: flex;
      flex-flow: row;
      height: calc(100% - 2.75rem);
      max-width: none;
      background-color: unset;
    }
  }

  &.page-overflow {
    .page-sheet-container {
      overflow-y: auto;
    }

    @media only screen and (max-width: $desktop_S) {
      overflow-y: hidden;
      overflow-x: auto;
    }
  }

  &.isZoomed {
    padding: 0;
    .page-sheet-description {
      margin: 0;
    }
    .page-sheet-container {
      @media screen and (max-width: $desktop_S) {
        height: 100%;
      }
    }
  }
}
@media only screen and (max-width: $desktop_M) {
  .page-sheet-container {
    max-width: 100%;
  }
}
