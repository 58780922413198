@use "theme/utils/variables.module.scss" as *;

.space-configurator-packCard {
  font-family: $primaryFont;
  position: relative;
  overflow: hidden;
  padding: 0.5rem;
  height: 24.5rem;
  width: 21rem;
  min-width: 20rem;
  box-shadow: 0px 0.25rem 0.815rem rgba(0, 0, 0, 0.25);
  border-radius: unset;

  @media screen and (min-width: $tablet) and (max-width: $desktop_S) {
    &:first-of-type {
      margin-left: 1.5rem;
    }
    &:last-of-type {
      margin-right: 1.5rem;
    }
  }

  &.included {
    .tecma-card-media {
      .price-container {
        display: none;
      }
    }
  }
  .tecma-card-media {
    position: relative;
    height: unset;
    flex: 0.8;
    transition: flex 1s;
    display: flex;
    align-items: center;
    .tecma-lazyImage {
      width: 100%;
      .tecma-spinner .dot {
        background-color: $accent;
      }
    }
    &.show-details {
      flex: 0.3;
      transition: flex 1s;
      min-height: 4rem;
      .price-container {
        transition: bottom 1s, z-index 1s 0.5s;
        bottom: -2rem;
        z-index: -1;
      }
    }
    img {
      height: 100%;
      width: 100%;
    }
    .tecma-button {
      position: absolute;
      font-family: $primaryFont;
      height: 2rem;
      top: 2rem;
      right: 0;
      border-radius: 0;
      background-color: $gray-50;
      color: $general;
      text-transform: uppercase;
      font-size: $smallFont;
      .tecma-icon path {
        fill: $general;
      }
    }
    .price-container {
      display: flex;
      position: absolute;
      height: 2rem;
      background-color: $general;
      color: $on-general-sub;
      bottom: 0;
      padding: 0 1rem;
      align-items: center;
      font-size: $defaultFont;
      white-space: pre-wrap; // flex is removing spaces in text -> https://github.com/Faithlife/styled-ui/issues/197
      transition: bottom 0.8s, z-index 1s;
      z-index: 1;
      &::after {
        content: "";
        width: 2rem;
        height: 100%;
        position: absolute;
        transform: skewX(40deg);
        border-left: 0.063rem solid transparent;
        left: 90%;
        background-color: $general;
      }
      span {
        font-size: $extraLargeFont;
        color: $accent;
      }
    }
  }
  .card-content {
    flex: 0.2 1 20%;
    transition: flex 1s;
    &.show-details {
      flex: 1;
      transition: flex 1s;
      animation: opacity 3s;
      overflow: auto;
    }
  }
  .tecma-card-header {
    text-transform: uppercase;
    h3 {
      margin-bottom: 0;
    }
  }
  .tecma-card-header,
  .tecma-card-content {
    padding: 0 1rem;
    color: $on-general;
  }

  .tecma-card-content {
    .description {
      font-size: $smallFont;
    }
  }
  .pack-details {
    display: flex;
    background-color: $general;
    flex-direction: column;
    padding: 2rem 1rem;
    .detail {
      display: flex;
      align-items: flex-start;
      font-family: $primaryFont;
      color: $on-general;
      font-size: $smallFont;
      line-height: 1.5rem;
      .tecma-icon {
        margin-top: 0.313rem;
        margin-right: 0.313rem;
        path {
          fill: $accent;
        }
      }
    }
  }
  .tecma-card-footer {
    display: flex;
    text-transform: uppercase;
    justify-content: space-between;
    align-items: center;
    height: 2.5rem;
    padding-left: 1rem;
    box-shadow: 0px -0.063rem 0 $accent;
    transition: background-color 0.5s;
    span {
      width: 100%;
      padding-left: 1rem;
      color: $on-general;
    }
    .tecma-button {
      border-radius: 0;
      background-color: $accent;
      min-width: 5rem;
      color: $general;
      .tecma-icon path {
        fill: $general;
      }
    }
    &.added {
      background-color: $accent;
      transition: background-color 0.5s;
      span {
        color: $general;
      }
      span:has(+ .tecma-button) {
        // add border to span only if is followed by a button
        border-right: 0.063rem solid $general;
      }
    }
  }
}

@media screen and (max-width: $desktop_S) {
  .space-configurator-packCard {
    margin-bottom: unset;
    height: 100%;
    max-height: 24.5rem;
  }
}

@media screen and (max-width: $tablet) {
  .space-configurator-packCard {
    width: 17.5rem;
    min-width: 17.5rem;
    min-height: 24.5rem;
    padding: 1rem;

    .tecma-card-media {
      .price-container {
        padding-left: 0;
      }
    }

    .card-content {
      .tecma-card-content,
      .tecma-card-header {
        padding: 0;
      }
      .tecma-card-content {
        color: $on-general;
        .description {
          font-size: $defaultFont;
        }
      }
    }

    .tecma-card-footer {
      height: 3rem;
      padding-left: 0;
      .tecma-button {
        height: 3rem;
      }
    }
  }
}

@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
