@use "../../../../theme/utils/variables.module.scss" as *;

.space-configurator-info-message {
  display: flex;
  align-items: flex-start;
  font-family: $secondaryFont;
  font-size: $smallFont;
  color: $on-general;

  .floatingContent-trigger {
    .tecma-icon path {
      fill: $accent;
    }
  }
  & p {
    font-family: $primaryFont;
    font-size: $defaultFont;
    color: $on-general;
    margin: 0;
    margin-left: 0.5rem;
    padding-top: 0.125rem;
  }
  &-tooltip {
    font-family: $secondaryFont;
    line-height: 1.25rem;
    text-align: start;
    padding: 1rem;
    font-size: $mediumFont;
    max-width: 17.5rem;
    background-color: $negative-active;
    span {
      font-family: $secondaryFontBold;
    }
  }
}
#tecma-floatingContent .tecma-floatingContent-container {
  .tecma-tooltip {
    border-radius: 0.5rem;
    background-color: $negative-active;
    color: $on-negative;
  }
  .floatingContent-arrow.tooltip-floatingContent {
    border-bottom-color: $negative-active;
  }
  &:focus {
    outline: none;
  }
}
