// @import "../../theme/utils/variables.module.scss";
@use "theme/utils/variables.module.scss" as *;

.am-modal-container {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 1800;
  display: flex;
  overflow: auto;
  width: 100vw;
  height: 100vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  background-color: $blanket-overlay;
  box-sizing: border-box;
  font-family: $primaryFont;
}

.am-modal-form {
  background: $primary;
  display: flex;
  position: fixed;
  left: 50%;
  top: 50%;
  width: 90%;
  height: auto;
  padding: 2rem 0;
  text-align: center;
  flex-direction: column;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  &.signupDone,
  &.recoveryDone {
    height: 12rem;
  }
  &.changePassword {
    min-height: 15rem;
  }
  &.confirm {
    min-height: 20rem;
  }
  &.signup {
    @media screen and (min-width: $mobile) {
      max-height: calc(100vh - 3rem);
    }
    @media screen and (min-width: $tablet) {
      max-height: fit-content;
      width: 90%;
    }
  }

  .close-button {
    position: absolute;
    right: -0.5rem;
    top: -0.5rem;
    background-color: $accent;

    .tecma-icon {
      path {
        fill: $on-accent;
      }
    }

    @media screen and (max-width: $tablet) {
      right: calc(50% - 1rem);
      top: -0.75rem;
      max-width: unset;
      max-height: unset;
    }
  }
  @media only screen and (min-width: $mobile) {
    width: 100%;
    max-width: calc(100% - 30px);
    max-height: calc(100% - 50px);
    &.keyboard-open {
      height: 100%;
    }
  }
  @media screen and (min-width: $tablet) {
    flex-direction: row;
    width: 90%;
    height: max-content;
    max-width: 50rem;
    max-height: 25rem;
    &.keyboard-open {
      height: max-content;
    }
  }
}

.am-modal-loader {
  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  width: 100%;
  height: 100%;
  background-color: $blanket-overlay;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  z-index: 1;
}

.am-email-footer {
  height: 25%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin: 10px 0;
}

.am-special-bottom {
  display: unset;
  position: absolute;
  left: 0%;
  right: 0%;
  bottom: 0%;
  height: 3px;
  background-color: $accent-border;
}

.am-recovery-title {
  height: 20%;
  color: $accent;
  position: relative;
  font-family: $secondaryFont;
  &::before {
    content: "";
    width: 100%;
    position: absolute;
    top: -6px;
    border-width: 0 0 1px;
    border-style: solid;
    color: $accent;
  }
}

.am-right-form {
  height: 100%;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: auto;
  &-register {
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: row;
    margin: 10px 0 30px;
  }
  &-editPass {
    align-items: center;
  }
  &-recovery {
    gap: 8px;
    align-content: flex-end;
    color: $on-primary;
  }
  &-login {
    margin-top: 5%;
  }
}
.am-email-footer-info,
.am-email-footer-actions {
  font-family: $secondaryFont;
  text-align: center;
}

.am-email-footer-actions {
  margin-top: 20px;
}

.am-recovery-success {
  width: 100%;
  color: $on-primary;
  font-family: $primaryFont;

  .am-recovery-subtitle {
    display: block;

    span {
      font-family: $primaryFontBold;
    }
  }
}
.am-recovery-header {
  padding-top: 20px;
  padding-bottom: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.am-recovery-subtitle {
  color: $on-primary;
  font-size: 18px;
  margin-top: 20px;
  display: inline-block;
  line-height: 1.4;
  align-self: center;
  width: 80%;
  justify-content: center;
  display: flex;
}

.am-error-message {
  color: $danger;
  text-align: center;
  width: 100%;
}

.am-email-footer-link {
  color: $accent;
  cursor: pointer;
  display: inline-block;
  position: relative;
  font-family: $secondaryFont;
  border-width: 0 0 1px;
  border-style: solid;
  line-height: 1.2;
}

.am-privacy-link {
  color: $on-primary;
  font-weight: bold;
}

@media only screen and (min-width: 300px) {
  .am-register-container {
    width: 100%;
  }
  .am-recovery-subtitle {
    font-size: 15px;
  }
}

@media only screen and (min-width: 350px) {
  .am-recovery-subtitle {
    font-size: 18px;
  }
}

@media only screen and (min-width: 350px) and (max-height: 550px) {
  .am-recovery-subtitle {
    margin-top: 0;
  }
}

@media screen and (min-width: $tablet) {
  .am-email-footer-info,
  .am-email-footer-actions {
    text-align: left;
  }
  .am-right-form.am-right-form-register {
    overflow: auto;
    margin-bottom: 15px;
    padding-left: 10px;
  }
}

@media only screen and (max-width: 1000px) and (max-height: 700px) and (orientation: landscape) {
  #rent-platform-app.turn-your-phone .am-modal-container {
    display: none;
  }
}

@media screen and (min-width: 1000px) and (orientation: portrait) {
  .am-modal-form.login {
    width: 90%;
  }
}

@media screen and (min-width: 1200px) {
  .am-modal-form.login {
    width: 55%;
  }
}

@media screen and (min-width: 1500px) {
  .am-modal-form.login {
    width: 45%;
  }
}
