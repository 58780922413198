@use "theme/utils/variables.module.scss" as *;

.space-type-step {
  padding: 1.5rem;
  @media only screen and (max-width: $tablet) {
    padding: 1rem 1rem 1.5rem;
  }
  .space-type-container {
    margin-top: 2rem;
    @media only screen and (max-width: $tablet) {
      margin: 1rem auto 0;
      max-width: 14.5rem;
      .availability {
        text-align: center;
        justify-content: center;
      }
    }
    .availability {
      display: flex;
      align-items: center;
      height: 2.5rem;
      &.hidden {
        visibility: hidden;
      }
      .tecma-icon {
        @media only screen and (max-width: $tablet) {
          min-width: 1rem;
        }
        + p {
          margin-left: 0.5rem;
        }
        path {
          fill: $accent;
        }
      }
      p {
        font-size: $defaultFont;
        font-family: $secondaryFont;
        font-weight: 600;
        color: $accent;
        span {
          font-family: $secondaryFontBolder;
          font-weight: 600;
        }
      }
    }
    .space-type-content {
      display: flex;
      align-items: flex-start;
      &.disabled {
        color: $on-disabled;
      }
      @media only screen and (max-width: $tablet) {
        flex-direction: column;
        gap: 0.5rem 0;
        > * {
          flex-grow: 0;
          flex-basis: auto;
        }
      }
      .tecma-valuePicker {
        font-family: $primaryFont;

        > * {
          background-color: $general;
          border-color: $general-border;
        }
        .tecma-button {
          &:hover {
            background-color: $general-hover;
          }
        }
        .tecma-icon path {
          fill: $on-general;
        }
        + .tecma-carousel {
          flex: 1;
          margin-left: 1.5rem;
          @media only screen and (max-width: $tablet) {
            margin-left: 0;
          }
        }
        .valuePicker-container {
          p {
            transition: transform 0.35s ease-in-out;
            font-size: $defaultFont;
            margin: 0;
          }
          &.disabled {
            background-color: $disabled;
            color: $on-disabled;
            p:first-child {
              text-transform: none;
              color: $on-general-disabled;
            }
          }
        }
      }
      .value-picker {
        color: $on-general;
        &.disabled {
          color: $on-general-disabled;
        }
      }
      .tecma-carousel {
        box-shadow: none;
        .space-info {
          color: $on-general;
          font-family: $secondaryFont;

          &.disabled {
            color: $on-general-disabled;
          }
        }
        .slide {
          align-items: flex-start;
          justify-content: flex-start;
          font-family: $secondaryFont;
          font-size: $defaultFont;
          .price {
            padding-top: 1rem;
            margin-top: 1rem;
            border-top: 0.125rem solid $general-border;
            font-family: $secondaryFontBold;
            width: fit-content;
          }
        }
      }
    }
  }
}
