@use "theme/utils/variables.module.scss" as *;

.space-configurator-pageContent {
  height: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
  position: relative;

  @media only screen and (max-width: $desktop_S) {
    flex-direction: column-reverse;
  }

  &.isZoomed {
    .pageContent-configurator {
      flex: 0;
      padding: 0;
    }

    .page-content-body {
      flex: 1;
      @media screen and (max-width: $desktop_S) {
        padding: 0;
      }
    }
  }

  .pageContent-configurator,
  .pageContent-carousel {
    height: 100%;
  }

  .pageContent-configurator {
    background-color: $general;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    box-sizing: border-box;
    width: 35rem;

    &.is-mobile {
      height: 60%;
      padding: 0;
      transition: height 1000ms;

      &.is-open {
        height: 100%;
      }
      &.is-shrinked {
        height: calc(100% - 6.5rem);
      }
    }

    @media only screen and (max-width: $desktop_S) {
      height: 50%;
      width: unset;
      flex-direction: row;
      flex: unset;
      overflow: hidden;
    }

    @media screen and (max-width: $tablet) {
      height: calc(100% - 16.125rem);
      flex-direction: column;
      padding: 0;
    }
  }

  .page-content-body {
    background-color: $gray-95;
    position: relative;
    flex: 1;
    overflow: hidden;

    &.is-mobile {
      height: calc(40% - 3rem);
      transition: height 1000ms;
      &.is-open {
        height: 0;
      }
      &.is-shrinked {
        height: 3.5rem;
      }

      .page-content-body-shrink-btn {
        position: absolute;
        bottom: 1rem;
        right: 1rem;
        z-index: 1;
        background-color: $accent;

        .tecma-icon {
          path {
            fill: $general;
            stroke: $general;
          }
        }
      }
    }

    @media only screen and (max-width: $desktop_S) {
      height: 50%;
      flex: unset;
    }
  }

  .page-content-overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.5);
    pointer-events: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem 0;
    opacity: 0;
    transition: opacity 1s ease-in-out;

    &.show-overlay {
      opacity: 1;
      .overlay-text {
        p {
          opacity: 1;
          visibility: visible;
        }
      }
    }

    &.is-shrinked {
      opacity: 1;
      .shrinked-overlay-text {
        opacity: 1;
        visibility: visible;
      }
    }

    .overlay-text,
    .shrinked-overlay-text {
      position: absolute;
    }

    p {
      font-family: $primaryFont;
      margin: 0;
      display: flex;
      gap: 0 0.3rem;
      color: $general;
      opacity: 0;
      visibility: hidden;
      transition: all 1s ease-in-out;

      span {
        font-family: $primaryFontBold;
      }

      .tecma-icon {
        path {
          fill: $general;
          stroke: $general;
        }
      }
    }
  }
}

.space-configurator-datePickerSection {
  z-index: 1;
}

@media screen and (max-width: $desktop_M) {
  .space-configurator-pageContent {
    .pageContent-configurator {
      width: 28rem;
    }
  }
}
@media screen and (max-width: $desktop_S) {
  .space-configurator-pageContent {
    .pageContent-configurator {
      height: 50%;
      width: unset;
      flex-direction: row;
      flex: unset;
      overflow: hidden;
    }
  }
}
