@use "theme/utils/variables.module.scss" as *;

.alert-popup {
  display: flex;
  flex-direction: column;
  &.tecma-modal {
    background-color: $general;
    &.medium {
      width: 42.5rem;
      @media screen and (max-width: $tablet) {
        width: 18.75rem;
      }
    }
  }
  &-header {
    align-self: flex-end;
    .tecma-button {
      &:hover {
        background-color: $general-hover;
      }
      .tecma-icon path {
        fill: $accent;
      }
    }
  }
  &-content {
    margin: 0 auto;
    width: 60%;
    text-align: center;
    font-family: $primaryFont;
    color: $on-general;
    @media screen and (max-width: $tablet) {
      width: 100%;
      padding: 1.5rem 1rem 0;
    }

    span {
      font-family: $primaryFontBold;
      color: $accent;
    }
  }
  &-footer {
    padding: 2rem;
    @media screen and (max-width: $tablet) {
      flex-direction: column-reverse;
      padding: 2.5rem 2rem 1.5rem;
      &.tecma-modal-footer {
        gap: 1rem;
      }
    }
  }
  &-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    .tecma-icon {
      height: 2.75rem;
      width: 2.75rem;
      path {
        fill: $accent;
      }
    }
    &-text {
      text-transform: uppercase;
      font-family: $primaryFontBold;
      font-size: $largeFont;
      color: $accent;
      margin: 1rem 0;
    }
  }
  &-button.tecma-button {
    padding: 1.5rem 1.25rem;
    font-family: $primaryFont;
    width: 12.5rem;

    &.confirm {
      background-color: $accent;
      color: $general;
      &:hover {
        background-color: $accent-hover;
      }
    }

    &.abort {
      color: $on-general;
      border-color: $on-general;
      &:hover {
        background-color: $general-hover;
      }
    }
  }
}
