@use "theme/utils/variables.module.scss" as *;

.space-configurator-complexStep {
  padding: 1.5rem;

  .complex-step-container {
    margin-top: 1.5rem;
    .space-configurator-card-item {
      @media only screen and (max-width: $desktop_S) {
        height: auto;
      }
      &.with-image {
        &:not(.active) {
          .tecma-card {
            &-media {
              img {
                @media screen and (max-width: $desktop_M) {
                  transform: translateY(calc(-100% + 2.5rem)) scale(1.2);
                }
                @media screen and (max-width: $tablet) {
                  transform: translateY(calc(-100% + 7.5rem)) scale(1.2);
                }
              }
            }
          }
        }
        .tecma-card {
          &-content {
            @media screen and (max-width: $desktop_M) {
              margin-top: 2.5rem;
            }
            @media screen and (max-width: $tablet) {
              margin-top: 7.5rem;
            }
          }
        }
      }
    }
  }

  .complex-step-mobile {
    &-select-btn {
      &.tecma-button {
        font-family: $secondaryFont;
        font-weight: 500;
        font-size: $mediumFont;
        margin-top: 2rem;
        background-color: $accent;
      }
    }

    &-selected-building {
      font-family: $primaryFont;
      color: $on-general;
      text-align: center;
      font-weight: 600;
      font-size: $defaultFont;
    }

    &-complex-selected {
      width: 100%;
      .tecma-card {
        width: 100%;
      }
    }

    &-availability {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      font-family: $secondaryFont;
      color: $accent;

      span {
        text-align: center;
      }

      .tecma-icon path {
        fill: $accent;
      }
    }
  }
}
