@use "../../../../theme/utils/variables.module.scss" as *;

$recap-transition-time: 1000ms;

.recap-footer {
  padding: 1.5rem;
  font-family: $primaryFont;
  background-color: $general;
  transition: all $recap-transition-time;

  &.is-mobile {
    padding: 0 1.5rem 1.5rem;
    &.is-open {
      padding: 0.5rem 1.5rem 1.5rem;
    }
  }
  &-desc {
    font-size: $mediumFont;
    color: $on-general;
    span {
      font-family: $primaryFontBold;
    }
  }
  p {
    margin-top: 0;
  }
  &-price {
    color: $accent;
    font-family: $secondaryFont;
    :first-child {
      font-size: $extraLargeFont;
      font-family: $secondaryFontBold;
    }
  }
  &-buttons {
    .tecma-button {
      position: relative;
      font-size: $defaultFont;
      font-family: $primaryFont;
      &.disabled {
        opacity: 1;
      }
      .tecma-icon {
        position: absolute;
        right: 1rem;

        path {
          transition: fill $recap-transition-time;
          fill: $on-accent;
        }
      }
    }
    &-save {
      font-family: $primaryFont;
      transition: all 300ms;
      &.is-mobile {
        order: 1;
      }
      &.secondary {
        background-color: $accent-alternative;
        color: $on-accent;
        @media (pointer: fine) {
          &:hover {
            background-color: $accent-alternative-dark;
          }
        }
        @media (pointer: coarse) {
          &:hover {
            background-color: $accent-alternative;
          }
        }
      }
      &.disabled {
        background-color: $disabled;
        color: $on-disabled;
      }
    }
    &-reset.tecma-button {
      padding: 0;
      color: $accent;
      font-family: $primaryFontBold;
      text-decoration: underline;
      margin-top: 0.5rem;
      &.is-mobile {
        transition: all 1000ms;
        height: 0;
        opacity: 0;
        margin-top: 0;
        pointer-events: none;
        &.is-open {
          pointer-events: initial;
          height: $BUTTON_MEDIUM_HEIGHT;
          opacity: 1;
          margin-bottom: 0.5rem;
        }
      }
      &.disabled {
        color: $disabled;
      }
      &:hover {
        background-color: unset;
        color: $accent-hover;
      }
    }

    @media screen and (max-width: $desktop_S) {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  @media screen and (max-width: $desktop_S) {
    border: 0;
  }
}

@media screen and (min-width: $desktop_S) and (max-width: $desktop_M) {
  .recap-footer {
    transition: transform 1500ms;
    transform: translate(0px, -100vh);
    z-index: -1;
    &.is-open {
      padding: 1.5rem;
      transition: transform 1000ms;
      transform: translate(0, 0);
    }
  }
}
