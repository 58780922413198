@use "theme/utils/variables.module.scss" as *;

.price-details {
  &.isOpenPriceDetails {
    height: 100%;
  }
  &__excluded-price-name {
    color: $accent;
    display: block;
    margin-left: 0;
    padding-bottom: 1rem;
    font-family: $secondaryFont;
    text-transform: lowercase;
    margin-top: 0;
    transition: margin-top 1s;
    &.isOpenRecap {
      margin-top: -0.5rem;
    }
    @media screen and (min-width: $tablet) {
      margin-top: -0.5rem;
    }
    @media screen and (min-width: $tablet) and (max-width: $desktop_M) {
      padding-left: 1.5rem;
    }
    @media screen and (max-width: $tablet) {
      padding-bottom: 0;
      width: max-content;
    }
  }
  &__button {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    border: 0;
    background-color: $general;
    transition: all 1s;
    padding: 0 1rem;
    padding-bottom: 1rem;
    cursor: pointer;
    &:hover {
      p {
        color: $accent-hover;
      }
      .tecma-icon {
        path {
          fill: $accent-hover;
        }
      }
    }
    p.price-details__button__text {
      margin: 0;
      font-family: $primaryFontRegular;
      font-size: $defaultFont;
      color: $accent;
      text-decoration: underline;
      display: flex;
      align-items: center;
      transition: color 300ms;
      @media screen and (min-width: $desktop_S) {
        &:hover {
          color: $accent-hover;
        }
      }
    }
    .tecma-icon {
      margin-left: 0.5rem;
      transform: rotate(0);
      transition: transform 1s;
      path {
        transition: fill 300ms;
        fill: $accent;
      }

      /* @media screen and (max-width: $desktop_M) {
        position: absolute;
        right: 0;
        margin-right: 1rem;
      } */
    }
    &.isOpenPriceDetails {
      .tecma-icon {
        transform: rotate(180deg);
      }
    }
    @media screen and (max-width: $desktop_M) {
      padding-left: 0;
      margin-left: 1.5rem;
    }
    @media screen and (max-width: $tablet) {
      padding: 0;
      width: max-content;
      margin-left: 0;
    }
  }
  &__content {
    display: grid;
    grid-template-rows: 0fr;
    transition: grid-template-rows 1s, margin 1s;
    background-color: $secondary-light;
    color: $on-secondary-light;
    margin: 0 1rem 0;
    &__table {
      overflow: hidden;
      @media screen and (min-width: $tablet) and (max-width: $desktop_S) {
        overflow: scroll;
        max-height: calc(50vh - $HEADER_HEIGHT - 14.685rem);
      }
    }
    @media screen and (max-width: $tablet) {
      margin: 0;
    }
    &.isOpenPriceDetails {
      grid-template-rows: 1fr;
      margin: 0 1rem 1rem;
      @media screen and (max-width: $tablet) {
        margin: 1rem 0 0;
      }
      @media screen and (min-width: $desktop_M) {
        margin: 0;
        margin-bottom: 1rem;
      }
    }
    &__title {
      font-family: $secondaryFontBolder;
      font-size: 14px;
      text-transform: uppercase;
      border-bottom: 1px solid $secondary-border;
      padding: 0.5rem;
      margin: 0;
    }
  }
  @media screen and (max-width: $tablet) {
    grid-template-rows: 0fr;
    flex-direction: column;
    padding-bottom: 0;
    transition: grid-template-rows 1s, opacity 1s;
    opacity: 0;
    &.isOpenRecap {
      display: grid;
      opacity: 1;
      grid-template-rows: 1fr;
    }
  }
}
