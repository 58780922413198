@use "../../../../theme/utils/variables.module.scss" as *;

.packs-recap {
  text-transform: uppercase;
  font-family: $primaryFontBolder;
  font-size: $largeFont;
  ul {
    padding-left: 1rem;
  }
  ul,
  p {
    margin: 0;
  }
}
