@use "theme/utils/variables.module.scss" as *;

.space-configurator-zoneFilter {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;

  @media screen and (max-width: $desktop_S) {
    margin-left: 1rem;
  }

  .see-zones-open {
    display: flex;
    width: 100%;
    justify-content: center;

    .see-zones-btn {
      border-color: $accent;
      color: $accent;

      .tecma-icon path {
        fill: $accent;
      }
    }
  }

  .tecma-button {
    font-family: $primaryFont;
    margin: 0.5rem 0.5rem 0.5rem 0;
    border: 1px solid $general-border;
    background-color: $general;
    color: $on-general;
    width: auto;

    &.see-zones-btn {
      font-family: $primaryFontBold;
    }

    &.selected {
      background-color: $accent-light;
      color: $on-accent-light;
      border: unset;

      .tecma-icon path {
        fill: $accent;
      }
    }
  }
}
