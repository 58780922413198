@use "theme/utils/variables.module.scss" as *;

$card-animation-curve: cubic-bezier(0.79, 0, 0.65, 0.95);

@mixin pseudo-background {
  content: "";
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: all 0.3s $card-animation-curve;
}

.space-configurator-card-item {
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  height: 100%;

  &.with-image {
    .tecma-card {
      &-content {
        margin-top: 3.875rem;
      }
    }
  }
  &:not(.with-image) {
    .tecma-card {
      &-content::after {
        transition-delay: 0.2s;
      }
    }
  }

  .tecma-card {
    font-family: $primaryFont;
    background-color: $general;
    border: 2px solid $general-border;
    color: $on-general;
    border-radius: unset;
    width: $cards_width_desktop;
    height: 100%;
    padding: 0;
    flex-grow: 1;
    position: relative;
    overflow: hidden;
    transition: color 0.3s $card-animation-curve,
      border-color 0.2s $card-animation-curve;

    @media screen and (max-width: $desktop_M) {
      width: $cards_width_desktop_small;
    }

    &-media {
      width: 100%;
      height: 100%;
      position: absolute;

      img {
        transition: max-height 0.3s $card-animation-curve,
          transform 0.3s $card-animation-curve;
        transform: translateY(calc(-100% + 3.875rem)) scale(1.2);
      }

      &::after {
        @include pseudo-background;
        height: 80%;
        z-index: 1;
        transform: translateY(100%);
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.55) 60%,
          rgba(0, 0, 0, 0)
        );
      }
    }

    &-content {
      position: relative;
      z-index: 1;
      height: 100%;

      &::after {
        @include pseudo-background;
        height: 100%;
        z-index: -1;
        transform: translateY(0);
        background-color: $secondary-light;
      }
    }

    &-header {
      display: flex;
      flex-direction: column;
      gap: 0.5rem 0;
      padding: 1.5rem 1rem;
      .subtitle {
        font-size: 0.875rem;
        margin: 0;
      }
      .title {
        height: 2.75rem;
        display: flex;
        align-items: center;
        justify-content: center;
        p {
          font-size: 1.25rem;
          margin: 0;
          word-wrap: break-word;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
          @media screen and (max-width: $desktop_M) {
            font-size: 1rem;
          }
        }
      }
    }

    &-footer {
      border-top: 1px solid $secondary-border;
      padding: 0.5rem 0 1rem;
      margin: 0 1rem;
      font-size: 0.75rem;

      .item-list {
        background-color: $secondary-light;
        padding: 1rem 0.5rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem 0;
      }

      p {
        margin: 0.5rem 0 0;
        span {
          font-size: 1.25rem;
          font-family: $primaryFontBold;
        }
      }
    }
  }

  &.active {
    .tecma-card {
      border-color: $accent;
      color: $general;
      &-media {
        img {
          transform: scale(1) translateY(0%);
        }
        &::after {
          transform: translateY(0);
        }
      }

      &-content {
        &::after {
          transform: translateY(100%);
        }
      }

      &-header {
        .title {
          font-family: $primaryFontBold;
        }
      }
    }
    &.confirmed {
      .tecma-card {
        border-color: $accent-alternative;
      }
    }
    &:not(.with-image) {
      .tecma-card {
        &-media {
          &::after {
            transition-delay: 0.2s;
          }
        }
        &-content {
          &::after {
            transition-delay: 0s;
          }
        }
      }
    }
  }
}
