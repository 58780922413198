@use "theme/utils/variables.module.scss" as *;

.spaceConfigurator-picker {
  display: flex;
  height: 3rem;
  border: 0.0625rem solid $general-border;
  width: fit-content;
  .picker-buttons-container {
    display: flex;
    flex-direction: column;
    border-left: 0.0625rem solid $general-border;
    .tecma-button {
      border-radius: unset;
      background-color: transparent;
      width: 1.815rem;
      &:hover {
        background-color: $general-hover;
      }
      .tecma-icon {
        path {
          fill: $accent;
        }
      }

      &.disabled {
        opacity: 1;
        .tecma-icon path {
          fill: $on-general-disabled;
        }
      }
    }
  }
  .picker-value-container {
    text-transform: uppercase;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: $secondaryFont;
    font-weight: 500;
    color: $on-general;
  }
}
