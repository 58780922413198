@use "../../../../theme/utils/variables.module.scss" as *;

.planimetry-recap {
  text-transform: uppercase;
  font-family: $primaryFontBolder;
  font-size: $largeFont;
  margin: 0;
  &-button.tecma-button {
    padding: 0;
    text-decoration: underline;
    font-size: $defaultFont;
    font-family: $primaryFontBold;
    text-align: left;
    color: $accent;
    height: unset;
    &:hover {
      background-color: unset;
    }
  }

  &-address {
    color: $on-general-sub;
    text-transform: uppercase;
    font-family: $primaryFontLight;
    font-size: 0.875rem;
  }
}
