.password-page {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
}

.password-page .password-page-illustration {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100vw;
  height: 100vh;
  display: block;
}
