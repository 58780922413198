@use "theme/utils/variables.module.scss" as *;

.space-configurator-card-list {
  width: 100%;
  &.use-pagination {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 27rem;

    @media only screen and (max-width: $desktop_M) {
      width: 23rem;
    }
  }

  .tecma-carousel {
    box-shadow: none;
    overflow: visible;
    width: 100%;
    height: auto;
    .slider-wrapper {
      overflow-x: visible;
      .slider {
        transform: translateX(-1rem);
        .slide {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          gap: 1rem;
          opacity: 1 !important;
          &.selected {
            margin: 0 1rem;
          }
        }
      }
    }
  }

  .card-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: $cards_width_desktop;
    @media screen and (max-width: $desktop_M) {
      width: $cards_width_desktop_small;
    }
  }

  .availability-description {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    font-family: $secondaryFontBold;
    color: $accent;
    max-width: 12rem; // TODO: decidere

    span {
      text-align: center;
    }

    .tecma-icon path {
      fill: $accent;
    }

    &.confirmed {
      color: $accent-alternative;

      .tecma-icon path {
        fill: $accent-alternative;
      }
    }
  }

  .cards-list-pagination {
    display: flex;
    gap: 1rem;

    .pagination-counter {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 2.625rem;
      color: $on-general;
      font-size: $defaultFont;
      font-family: $primaryFont;
    }

    .tecma-button {
      background-color: transparent;
      .tecma-icon {
        path {
          fill: $on-general;
        }
      }
    }
  }

  .card-list-mode {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 1rem;
    scroll-behavior: smooth;

    &.horizontal-scroll {
      flex-wrap: nowrap;
      overflow-x: scroll;

      /* Hide the scrollbar for Chrome, Safari and Opera */
      &::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none; /* Internet Explorer and Edge */
      scrollbar-width: none; /* Firefox */

      & > *:first-child {
        margin-left: 1rem;
      }
      & > *:last-child {
        margin-right: 1rem;
      }
    }
  }
}
