@use "../../../../theme/utils/variables.module.scss" as *;

.extra-space-type {
  font-family: $primaryFont;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin: 1.5rem;

  @media only screen and (max-width: $tablet) {
    margin: 1rem 1rem 1.5rem;
    gap: 1rem;

    .space-configurator-info-message {
      border-bottom: solid 1px $on-general;
      padding-bottom: 1.5rem;
    }
  }

  &:not(:last-child) {
    border-bottom: solid 1px $general-border;
    padding-bottom: 1.5rem;
  }
}
