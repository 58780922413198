@use "../../../theme/utils/variables.module.scss" as *;

.space-configurator-floor-step {
  padding: 1.5rem;
  @media only screen and (max-width: $tablet) {
    padding: 1rem 1rem 1.5rem;
  }
  .floor-container {
    margin-top: 2rem;
    @media only screen and (max-width: $tablet) {
      margin-top: 1.5rem;
    }
    &-availability {
      display: flex;
      align-items: center;
      height: 2.5rem;
      .tecma-icon {
        + p {
          margin-left: 0.5rem;
        }
        path {
          fill: $accent;
        }
      }
      &-text {
        color: $accent;
        font-family: $secondaryFont;
        font-weight: 600;
        font-size: $defaultFont;
      }
    }
    .floor-content {
      display: flex;
      flex-direction: column;

      @media screen and (max-width: $desktop_S) {
        flex-direction: row;
      }
      @media screen and (max-width: $tablet) {
        flex-direction: column;
        align-items: center;
        gap: 0.75rem 0;
        .tecma-valuePicker {
          width: fit-content;
        }
      }
      .tecma-valuePicker {
        font-family: $primaryFont;

        .tecma-button,
        .valuePicker-container {
          background-color: $general;
          border-color: $general-border;
        }

        .tecma-button {
          width: 3rem;
          &:hover {
            background-color: $general-hover;
          }
          &.disabled {
            background-color: $disabled;
            color: $on-disabled;
            .tecma-icon path {
              fill: $on-disabled;
            }
          }
        }

        .tecma-icon path {
          fill: $on-general;
        }
        + .tecma-carousel {
          margin-left: 1.5rem;
        }
        .valuePicker-container {
          width: 3rem;
          p {
            transition: transform 0.35s ease-in-out;
            margin: 0;
            color: $on-general;
          }
        }
      }
      .floor-total-floors {
        font-family: $primaryFont;
        font-size: $defaultFont;
        margin-top: 0.5rem;
        color: $on-general;

        @media screen and (max-width: $desktop_S) {
          margin: 0;
          padding-left: 1.5rem;
          align-self: center;
        }
        @media screen and (max-width: $tablet) {
          padding: 0;
        }
      }
    }
  }
}
