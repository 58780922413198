@use "theme/utils/variables.module.scss" as *;

#packs-modal {
  .modal-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2.5rem 1rem;
    padding: 1.5rem 0;
    overflow-y: auto;
    height: 100%;

    .tecma-card:last-child {
      margin-bottom: 10rem;
    }
  }
}
