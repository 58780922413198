@use "../../../../theme/utils/variables.module.scss" as *;

.recap-content {
  height: 0;
  transition: border-top 1s, border-bottom 1s, height 1s;
  background-color: $general;
  overflow: auto;
  border-top: 0 solid rgba(179, 179, 179, 0);
  border-bottom: 0 solid rgba(179, 179, 179, 0);
  @media screen and (min-width: $desktop_M) {
    border-top: 1px solid rgba(179, 179, 179, 1);
  }
  &.is-open {
    height: 100%;
    border-top: 1px solid rgba(179, 179, 179, 1);
    border-bottom: 1px solid rgba(179, 179, 179, 1);
    @media screen and (max-width: $tablet) {
      height: 100vh;
    }
  }

  .recap-building {
    padding: 1rem;
    border: 1px solid $general-border;
    max-width: 250px;
    margin: 1rem 1.5rem;
    &-name {
      text-transform: uppercase;
      font-family: $primaryFontBolder;
      font-size: $largeFont;
      margin: 0;
      color: $on-general;
    }
  }
}
