@use "theme/utils/variables.module.scss" as *;

.step-wizard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.5rem;
  min-height: 4rem;
  background-color: $secondary-light;
  transition: 500ms linear;
  width: 100%;
  &-wrapper {
    display: flex;
    align-items: center;
    &-icon {
      background-color: $on-disabled;
      transition: 500ms;
      path {
        fill: $on-disabled;
      }
    }
    &-title {
      font-family: $primaryFont;
      font-size: $mediumFont;
      padding: 0 1rem;
      color: $on-disabled;
      margin: 0;
      transition: color 300ms linear;
    }
  }
  &-button {
    min-width: 8rem;
    font-family: $secondaryFont;
    font-size: $defaultFont;
    height: max-content;
    background-color: $accent;
    color: $general;
    text-transform: capitalize;
    .tecma-icon {
      transform: rotate(0);
      transition: transform 500ms linear;
      path {
        fill: $on-accent;
      }
    }
  }

  .step-loader {
    path {
      fill: $accent;
    }
  }

  .step-wizard-header-wrapper-icon path {
    fill: $general;
  }
  &-mobile.tecma-button.medium {
    width: 100%;
    padding: 0;
    height: 4rem;
  }

  &.is-active {
    .arrow-mobile.tecma-icon {
      transform: rotate(0);
      transition: transform 500ms;
    }
    .step-wizard-header-wrapper-title {
      color: $on-secondary-light;
    }
    .step-wizard-header-wrapper-icon {
      background-color: $accent;
      &:hover {
        background-color: $accent-hover;
      }
    }
  }

  &.is-open {
    .arrow-mobile.tecma-icon {
      transform: rotate(180deg);
      path {
        fill: $on-secondary-light;
      }
    }
    .step-wizard-header-button {
      &.disabled {
        background-color: $disabled;
        color: $on-disabled;
        outline-color: $disabled;
        opacity: 1;
        .tecma-icon path {
          fill: $on-disabled;
        }
      }
      .tecma-icon {
        transform: rotate(180deg);
        path {
          fill: $general;
        }
      }
    }
  }

  &.is-completed {
    .step-wizard-header-wrapper-icon,
    .step-wizard-header-button {
      background-color: $accent-alternative;
      &:hover {
        background-color: $accent-alternative-dark;
      }
      .tecma-icon {
        path {
          fill: $on-accent;
        }
      }
    }
    .arrow-mobile.tecma-icon {
      path {
        fill: $accent-alternative;
      }
    }
  }

  &.is-modified {
    .step-wizard-header-wrapper-icon {
      background-color: $accent;
    }
    .step-wizard-header-button {
      outline-color: $accent;
      color: $general;
      background-color: $accent;
      &:hover {
        background-color: $accent-hover;
        outline-color: $accent-hover;
      }
    }
    .arrow-mobile.tecma-icon {
      path {
        fill: $accent;
      }
    }
  }
}
