@use "theme/utils/variables.module.scss" as *;

.space-configurator-plan-details {
  &.horizontal {
    display: flex;
    gap: 0 0.5rem;
    & > div {
      display: flex;
      align-items: center;
      gap: 0 0.5rem;
      &:not(:last-child, :nth-last-child(2)) {
        &::after {
          content: "";
          display: flex;
          top: 0;
          height: 1rem;
          width: 0.125rem;
          background-color: $general-border;
        }
      }
      &:last-child:not(:empty) {
        &::before {
          content: "";
          display: flex;
          top: 0;
          height: 1rem;
          width: 0.125rem;
          background-color: $general-border;
        }
      }
    }
  }
}
