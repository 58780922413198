@use "theme/utils/variables.module.scss" as *;

.space-configurator-drawerFooter {
  margin-top: auto;

  .drawer-footer-item {
    display: flex;
    align-items: center;
    gap: 0 0.5rem;
    padding: 0 0.5rem;
    color: $on-general;
    font-family: $secondaryFont;

    p {
      margin: 0.5rem 0;

      span {
        font-family: $secondaryFontBolder;
      }
    }

    .tecma-icon path {
      fill: $on-general;
    }
  }

  .mobile-logout {
    position: relative;
    justify-content: flex-start;
    color: $on-general;
    font-family: $secondaryFontBolder;
    font-size: $mediumFont;
    padding: 1rem 0.5rem 0.5rem;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      width: calc(100% - 1rem);
      height: 1px;
      background-color: $general-border;
    }
  }
}
