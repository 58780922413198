@use "theme/utils/variables.module.scss" as *;

.pack-step {
  padding: 1.5rem;

  @media only screen and (max-width: $tablet) {
    padding: 1rem 1rem 1.5rem;
  }

  &-add-pack-btn {
    &.tecma-button {
      font-family: $secondaryFont;
      font-weight: 500;
      font-size: $mediumFont;
      margin-top: 2rem;
      background-color: $accent;

      .tecma-icon path {
        fill: $general;
      }
    }
  }

  &-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1.125rem;

    @media only screen and (min-width: $desktop_S) {
      gap: 1.5rem;
    }

    @media only screen and (max-width: $tablet) {
      margin-top: 2rem;
    }
  }
}
