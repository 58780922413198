@use "../../../../theme/utils/variables.module.scss" as *;

.space-configurator-planimetry-picker {
  font-family: $primaryFont;
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  margin: 1.5rem 0 2rem;

  @media screen and (max-width: $tablet) {
    flex-wrap: nowrap;
    overflow-x: scroll;
    gap: 1rem;
    margin: 1.5rem 0;

    /* Hide the scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* Internet Explorer and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
