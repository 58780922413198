@use "../theme/utils/variables.module.scss" as *;
@import "../theme/utils/utilities.scss";

body {
  margin: 0;
  box-sizing: border-box;
}

body,
html,
#root {
  // keep 100% height for Chrome bug on android
  // see https://bugs.chromium.org/p/chromium/issues/detail?id=844848#c4
  height: 100%;
}

body * {
  box-sizing: border-box;

  // temporarily disable all focus style from buttons
  .tecma-button:focus {
    outline: none;
  }
  .tecma-valuePicker {
    .tecma-button:hover {
      background-color: $general-hover;
    }
  }
  .tecma-spinner.dotted .dot {
    background-color: $accent;
  }
}

strong {
  font-family: $primaryFontBold;
}

:root {
  overflow: hidden;
  .page-loader {
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    .dot {
      background-color: $accent;
      transition: all 300ms;
    }
  }
}
