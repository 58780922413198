@use "../../../../theme/utils/variables.module.scss" as *;

.extra-space-item {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  height: 5rem;
  width: 100%;
  border: solid 1px $general-border;
  background-color: $general;
  transition: border-color 300ms linear;

  @media only screen and (max-width: $tablet) {
    flex-direction: column;
    gap: 0.5rem 0;
    padding: 1rem;
    height: fit-content;
  }

  &-name {
    font-family: $primaryFont;
    text-transform: uppercase;
    text-align: left;
    color: $on-general;
  }

  &-label {
    display: flex;
    align-items: center;
    .floatingContent-trigger {
      display: flex;
      margin-right: 0.5rem;
      .tecma-icon {
        path {
          fill: $accent;
        }
      }
    }
  }

  &-tooltip {
    font-family: $secondaryFont;
    font-size: 0.875rem;
    line-height: 1.25rem;
    text-align: start;
    background-color: $negative-active;
    color: $on-negative;
    max-width: 15rem;
    padding: 0.75rem 1rem;
    span {
      font-family: $secondaryFontBold;
    }
  }

  &-availability {
    font-size: 0.9rem;
    text-transform: uppercase;
    color: $accent;
    display: flex;
    align-items: center;
    .extra-space-included {
      color: $general;
      background-color: $accent;
      padding: 0.5rem;
      border-radius: 0.125rem;
      width: fit-content;
      p {
        margin: 0;
      }
    }
    @media only screen and (max-width: $tablet) {
      font-size: $mediumFont;

      p {
        margin: 0;
      }
    }

    .tecma-valuePicker {
      font-family: $primaryFont;

      .valuePicker-container,
      .tecma-button {
        background-color: $general;
        border-color: $general-border;
      }

      .tecma-button {
        &:hover {
          background-color: $general-hover;
        }
        &.disabled {
          opacity: 1;
          .tecma-icon {
            path {
              fill: $on-general-disabled;
            }
          }
        }
      }

      .valuePicker-container {
        width: 2.8125rem;
        p {
          margin: 0;
          color: $on-general;
        }
      }
      .tecma-icon {
        width: 1.3rem;
        height: 1.3rem;
        min-width: unset;
        min-height: unset;
      }
      .tecma-icon path {
        fill: $on-general;
      }
    }
  }

  &.disabled {
    cursor: not-allowed;
    background-color: $disabled;
    border: 1px solid $disabled;

    .extra-space-item-name {
      color: $on-general;
    }

    .tecma-button {
      cursor: not-allowed;
      pointer-events: none;
    }
  }
}
