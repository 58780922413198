// @import '../../../../theme/utils/variables.module.scss';
@use "theme/utils/variables.module.scss" as *;

.react-tel-input {
  .country-list {
    bottom: -10px;
    width: 100%;
    max-width: 300px;
    text-align: left;
    max-height: 150px !important;
    .country-name {
      color: $on-general;
    }
  }

  .am-register-phoneNumber:not(:placeholder-shown) {
    padding: 14px 2px 1px;
    transition: padding 0.1s ease-in-out;
  }

  .special-label {
    display: flex;
    color: $accent;
    font-size: 10px;
    position: absolute;
    top: 0;
    left: calc(14% + 13px);
    background: transparent;
    transform-origin: top left;
    transform: translateY(100%) scale(0);
    transition: all 0.1s ease-in-out;
  }

  .flag-dropdown {
    border-radius: unset !important;
    border-bottom: 3px solid $accent !important;
    position: unset !important;
  }
}

.react-tel-input-active .special-label {
  transform: translateY(0) scale(1);
}

@media only screen and (min-width: 300px) {
  .react-tel-input {
    .special-label {
      left: calc(20% + 13px);
    }
  }
}

@media screen and (min-width: $tablet) {
  .react-tel-input {
    .special-label {
      left: calc(14% + 13px);
    }
  }
}
